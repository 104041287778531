<template>
  <div>
    <h1>Impressum</h1>
    <h4>Angaben gemäß § 5 DDG</h4>
    <v-card color="bgWarning mt-5" shaped>
      <v-card-text>
        <h1 class="white--text">
          Um Floppz zu kontaktieren, bitte die Optionen unter <router-link :to="{name: 'Contact'}">KONTAKT</router-link> in betracht ziehen
        </h1><br>
        <h3 class="white--text">
          Nachrichten an den Seitenbetreiber werden <strong>nicht</strong> an Floppz weitergeleitet!
        </h3>
      </v-card-text>
    </v-card>
    <v-card color="bgThird mt-5" shaped>
      <v-card-text class="white--text">
        <h3>Inhaber und Verantwortlicher</h3>
        <br>
        <strong>{{ getName }}</strong><br>
        {{ getStreet }}<br>
        {{ getCity }}<br>
        E-Mail: {{ getMail }}floppz.de<br>
        <v-card color="bgError mt-5" shaped>
          <v-card-text>
            <h3 class="white--text">
              <v-icon>mdi-alert-circle-outline</v-icon>
              ACHTUNG
              <v-icon>mdi-alert-circle-outline</v-icon>
              <v-divider class="mt-3 mb-3"></v-divider>
              Diese Anschrift verweist <strong>NICHT</strong> auf Floppz und ist somit nicht für Pakete oder Post für ihn gedacht!<br>
            </h3>
            <h4 class="white--text">
              Um mit Floppz in Kontakt zu treten nutzt bitte die üblichen und bekannten Wege, bzw. die Mail siehe oben<br>
            </h4>
          </v-card-text>
        </v-card>
        <br>
        <br>
        <h3>
          <router-link :to="{ name: 'Help' }">Datenschutzinformationen hier</router-link>
        </h3>
      </v-card-text>
    </v-card>
    <v-card v-if="false" color="bgInfo mt-5" shaped>
      <v-card-text class="white--text">
        <p>
          <strong>Haftungsausschluss:</strong><br>
          <br>
          <strong>Haftung für Inhalte</strong><br>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.<br>
          Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.<br>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.<br>
          Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.<br>
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.<br>
          Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br>
          <br>
          <strong>Haftung für Links</strong><br>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.<br>
          Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.<br>
          Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.<br>
          Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.<br>
          Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.<br>
          Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br>
          <br>
          <strong>Urheberrecht</strong><br>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.<br>
          Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
          Autors bzw. Erstellers.<br>
          Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.<br>
          Insbesondere werden Inhalte Dritter als solche gekennzeichnet.<br>
          Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis.<br>
          Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          <br><br>
          <strong>Datenschutz</strong><br>
          Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich.<br>
          Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger
          Basis.<br>
          Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.<br>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.<br>
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.<br>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und
          Informationsmaterialien wird hiermit ausdrücklich widersprochen.<br>
          Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.<br>
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PageImpressum',
  data() {
    return {
      contact: {
        name: ['h', 't', 'r', 'i', 'H', ' ', 'c', 'r', 'a', 'M'],
        mail: ['x', 'e', 'l', 'p'],
        street: ['0', '5', ' ', 'g', 'e', 'W', '-', 'g', 'i', 'e', 'w', 'Z', '-', 'n', 'a', 'f', 'e', 't', 'S'],
        city: ['n', 'e', 'g', 'n', 'i', 'r', 't', 'r', 'ä', 'G', ' ', '6', '1', '1', '1', '7']
      }
    }
  },
  computed: {
    getName() {
      const name = this.contact.name
      return name.reverse().join('')
    },
    getStreet() {
      const street = this.contact.street
      return street.reverse().join('')
    },
    getCity() {
      const city = this.contact.city
      return city.reverse().join('')
    },
    getMail() {
      const mail = this.contact.mail
      return `${mail.reverse().join('')}${decodeURIComponent('%40')}`
    }
  }
}
</script>

<style scoped>
.maillink {
  color: orange;
}
.maillink:visited {
  color: darkorange;
}
</style>