import { defineStore } from 'pinia'
import axios from "axios";

const webServer = process.env.VUE_APP_WEB_SERVER

export const houseStore = defineStore('houseStore', {
  state: () => {
    return {
      solarData: []
    }
  },
  actions: {
    async fetchSolarData() {
      const { data: jsonData } = await axios.get(`${webServer}/json/house.json`)
      if (jsonData) {
        this.solarData = jsonData
      }
    }
  },
  getters: {
    solarAktuell: (state) => {
      const anzahl = state.solarData.length
      return state.solarData[anzahl - 1]
    },
  }
})