<script setup>
import { computed, onBeforeUnmount, ref } from 'vue'
import { droppzStore } from '@/store/droppz'
// STORE
const drStore = droppzStore()
// EMITS
// eslint-disable-next-line no-undef
const emit = defineEmits(['updateMounted'])
// DATA
const loading = ref(false)
const tableSearch = ref('')
const tableHeaders = [
  {
    text: 'User',
    value: 'user'
  },
  {
    text: 'Stunden',
    value: 'WT'
  },
  {
    text: 'Dauer',
    value: 'duration'
  },
  {
    text: 'Aktiv',
    value: 'ON'
  },
  {
    text: 'Zuletzt gesehen',
    value: 'LS'
  }
]
const tableFooter = {
  itemsPerPageOptions: [25, 50, 100, 250],
  showCurrentPage: true,
  showFirstLastPage: true,
  itemsPerPageText: 'Einträge pro Seite'
}
// COMPUTED
const watchTimeData = computed(() => {
  const preparedData = []
  if (drStore.getUserDroppz) {
    drStore.getUserDroppz.forEach((ud) => {
      const tempUser = { ...ud }
      tempUser.duration = calculateDuration(tempUser.WT)
      tempUser.LS = lastSeenDuration(tempUser.LS)
      preparedData.push(tempUser)
    })
  }
  return preparedData
})
// METHODS
function lastSeenDuration(time) {
  if (isNaN(time) || !time) {
    return 'NA'
  }
  const now = new Date()
  const last = new Date(time)
  const diff = now - last
  // TODO: add as global function
  let text = ''
  time = parseInt(diff)
  const ms = time % 1000
  time = (time - ms) / 1000
  let sec = time % 60
  time = (time - sec) / 60
  let min = time % 60
  time = (time - min) / 60
  let hour = time % 24
  time = (time - hour) / 24
  let days = time % 7
  time = (time - days) / 7
  if (time > 0) {
    text = `${time}w `
  }
  if (days > 0) {
    text += `${days}d `
  }
  if (hour > 0) {
    text += `${hour}h `
  }
  if (min > 0) {
    text += `${min}m `
  }
  if (!text) {
    text = 'Jetzt'
  }
  return text.trim()
}
function displayCalculateDuration(time) {
  let weekStr = ''
  let dayStr = ''
  if (time >= 24) {
    let days = Math.floor(time / 24)
    let weeks = Math.floor(days / 7)
    days -= weeks * 7
    if (weeks > 0) {
      weekStr = weeks > 1 ? `<strong>${weeks}</strong> Wochen` : `<strong>1</strong> Woche`
    }
    if (days > 0) {
      dayStr = days > 1 ? `<strong>${days}</strong> Tage` : `<strong>1</strong> Tag`
    }
    return `${weekStr} ${dayStr}`
  }
  else {
    return 'unter 1 Tag'
  }
}
function calculateDuration(time) {
  const htmlValue = displayCalculateDuration(time)
  return htmlValue.replaceAll('<strong>', '').replaceAll('</strong>', '')
}
onBeforeUnmount(() => {
  clearTimeout(drStore.fetchInterval)
})
// CREATED
function created() {
  emit('updateMounted')
  drStore.fetchUserDroppzTimed()
}
created()
</script>

<template>
  <v-data-table
    id="wtTable"
    :footer-props="tableFooter"
    :headers="tableHeaders"
    :items="watchTimeData"
    :loading="loading"
    :search="tableSearch"
    class="mt-5 shaped"
    sort-by="WT"
    sort-desc
  >
    <template v-slot:top="{ pagination, options, updateOptions }">
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="tableSearch"
            class="mx-2"
            clearable
            label="Suche"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-data-footer
            :items-per-page-options="tableFooter.itemsPerPageOptions"
            :items-per-page-text="tableFooter.itemsPerPageText"
            :options="options"
            :pagination="pagination"
            :show-current-page="true"
            :show-first-last-page="true"
            @update:options="updateOptions"
          ></v-data-footer>
        </v-col>
      </v-row>
    </template>

    <template #[`item.ON`]="{item}">
      <v-icon
        v-if="item.ON"
        color="success"
      >
        mdi-check
      </v-icon>
      <v-icon
        v-else
        color="warning"
      >
        mdi-close
      </v-icon>
    </template>

    <template #[`item.duration`]="{item}">
      <span v-html="displayCalculateDuration(item.WT)"></span>
    </template>

    <template #[`item.WT`]="{item}">
      {{ item.WT.toFixed(2) || '-' }}
    </template>
  </v-data-table>
</template>

<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(200, 200, 200, .05) !important;
}
</style>