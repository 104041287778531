<template>
  <div>
  <v-card
    v-show="canShareSupport"
    outlined
    elevation="2"
    color="floppzSecondary"
  >
    <v-card-title
      class="content-bg-info-text font-weight-bold"
      style="background-color: #222222"
    >
      Support im Stream teilen?
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-textarea
            v-model="supportShareMessage"
            label="TTS Nachricht?"
            clearable
            placeholder="Gebe hier deine Nachricht ein (maximal 160 Zeichen)"
            rows="5"
            maxlength="160"
            counter
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-btn
            :small="smallSmartPhone > 400"
            color="bgWarning"
            @click="shareSupportInChat"
          >
            Jetzt im Stream teilen
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
    <!-- SUPPORT SELECTION -->
    <v-select
      v-model="selectedSupport"
      label="Support"
      :items="supportTypes"
      item-value="id"
      item-text="text"
      @change="getSupportText"
    />
    <!-- BUTTONS -->
    <v-row no-gutters>
      <!-- NO SUPPORT ACTIVE -->
      <v-col v-show="currentLvl === 0">
        <v-btn
          small
          color="bgInfo"
          :disabled="disabledBalance"
          @click="forceChangeLevel"
        >
          <v-icon left>
            mdi-timer-play-outline
          </v-icon>
          Jetzt starten
        </v-btn>
      </v-col>
      <!-- DOWNGRADE SUPPORT LEVEL -->
      <v-col v-show="currentLvl >= 1">
        <v-btn
          :small="smallSmartPhone > 400"
          color="bgInfo"
          :disabled="disabledNextCycle"
          @click="changeLevelNext"
        >
          <v-icon left>
            mdi-update
          </v-icon>
          <span v-if="smallSmartPhone > 400">
            zum nächsten Termin wechseln
          </span>
          <span v-else>
            zum nächsten<br>
            Termin wechseln
          </span>
        </v-btn>
      </v-col>
      <!-- UPGRADE SUPPORT LEVEL -->
      <v-spacer />
      <v-col v-show="currentLvl >= 1">
        <v-btn
          :small="smallSmartPhone > 400"
          color="primary"
          :disabled="disabledUpgrade"
          @click="forceChangeLevel"
        >
          <v-icon left>
            mdi-arrow-up-bold-hexagon-outline
          </v-icon>
          Jetzt wechseln
        </v-btn>
      </v-col>
    </v-row>
    <!-- EXAMPLE -->
    <v-row>
      <v-col>
        Beispielrechnung:<br />
        {{ exampleCalculation }}
      </v-col>
    </v-row>
    <confirmation-dialog
      ref="confirm_dialog"
    />
  </div>
</template>

<script>
import {userStore} from '@/store/user'
import {useSupportStore} from '@/store/support'
import ConfirmationDialog from '@/components/globalComponents/ConfirmationDialog.vue'
export default {
  name: "FoundationSupport",
  components: {ConfirmationDialog},
  setup() {
    return {
      user: userStore(),
      support: useSupportStore()
    }
  },
  data() {
    return {
      // Support
      selectedSupport: 1,  // default selection
      supportTypes: [
        {
          id: 1,
          text: 'Stufe 1 (5€ für 1 Zyklus [20 Streamtage])',
          value: 5,
          disabled: false
        },
        {
          id: 2,
          text: 'Stufe 2 (10€ für 1 Zyklus [20 Streamtage])',
          value: 10,
          disabled: false
        },
        {
          id: 3,
          text: 'Stufe 3 (25€ für 1 Zyklus [20 Streamtage])',
          value: 25,
          disabled: false
        },
        {
          id: 4,
          text: 'Stufe 4 (100€ für 1 Zyklus [20 Streamtage])',
          value: 100,
          disabled: false
        },
      ],
      supportShareMessage: '',
    }
  },
  computed: {
    smallSmartPhone() {
      return window.innerWidth || 1000
    },
    currentLvl() {
      return this.support.supportInfo.supporter
    },
    daysRemain() {
      return this.support.supportInfo.supportdaysremain
    },
    deposit() {
      return this.support.supportInfo.deposit
    },
    nackenKlatschen() {
      return this.support.supportInfo.nackenklatschen || 0
    },
    nextCycle() {
      return this.support.supportInfo.supportnextcycle || this.support.supportInfo.supporter
    },
    canShareSupport() {
      return this.support.supportInfo.supportshare || false
    },
    exampleCalculation() {
      let returnStr = ''
      let amount = 1
      const selectedSupport = this.supportTypes.find(level => level.id === this.selectedSupport)
      if (selectedSupport) {
        amount = selectedSupport.value
      }
      returnStr = `1 Monat = ${amount}€ | 3 Monate = ${amount * 3}€ | 6 Monate = ${amount * 6}€`
      return returnStr
    },
    disabledBalance() {
      return this.deposit < this.supportTypes.find(support => support.id === this.selectedSupport).value
    },
    disabledNextCycle() {
      if (this.selectedSupport === 0) return true
      return (
        this.deposit < this.supportTypes.find(support => support.id === this.selectedSupport).value ||
        this.selectedSupport === this.nextCycle
      )
    },
    disabledUpgrade() {
      if (this.selectedSupport === 0) return true
      return (
        this.deposit < this.supportTypes.find(support => support.id === this.selectedSupport).value ||
        this.selectedSupport <= this.currentLvl
      )
    }
  },
  methods: {
    getSupportText() {
      this.support.getInfoText(this.selectedSupport)
    },
    async forceChangeLevel() {
      const buttonOptions = {
        dialogTitle: 'Möchtest du wirklich direkt wechseln?',
        dialogText: this.support.forceChangeText,
      }
      const confirmed = await this.$refs.confirm_dialog.showDialog(buttonOptions)
      if (confirmed) {
        const updatePayload = {
          uid: this.user.userData.id,
          tier: this.selectedSupport
        }
        await this.support.updateSupportLevel(updatePayload, true)
      }
    },
    async changeLevelNext() {
      const buttonOptions = {
        dialogTitle: 'Möchtest du wirklich zum nächsten Zyklus wechseln?',
        dialogText: this.support.changeText,
      }
      const confirmed = await this.$refs.confirm_dialog.showDialog(buttonOptions)
      if (confirmed) {
        const updatePayload = {
          uid: this.user.userData.id,
          tier: this.selectedSupport
        }
        await this.support.updateSupportLevel(updatePayload)
      }
    },
    shareSupportInChat() {
      const sharePayload = {
        uid: this.user.userData.id,
        desc: this.supportShareMessage
      }
      this.support.shareSupport(sharePayload)
      this.supportShareMessage = ''
      this.support.getSupportInfo(this.user.userData.id)
    }
  },
  async created() {
    await this.support.getSupportInfo(this.user.userData.id)
    await this.$nextTick()
    if (this.nextCycle) {
      this.selectedSupport = this.nextCycle
    }
  },
  watch: {
    nextCycle(newVal) {
      if (newVal !== this.selectedSupport) {
        this.selectedSupport = newVal
        this.getSupportText()
      }
    }
  }
}
</script>

<style scoped>
.content-bg-info-text {
  background-color: #404040;
}
</style>