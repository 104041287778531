import { defineStore } from 'pinia'
import axios from "axios";

const webServer = process.env.VUE_APP_WEB_SERVER

export const globStore = defineStore('globStore', {
  state: () => {
    return {
      lotto: [],
      aktien: [],
      admin: []
    }
  },
  actions: {
    async fetchGlobData() {
      const { data: jsonData } = await axios.get(`${webServer}/json/Glob.json`)
      if (jsonData) {
        this.lotto = jsonData.Lotto
        this.aktien = jsonData.Aktien
        this.admin = jsonData.Admin
      }
    }
  },
  getters: {
    getUserAktien: (state) => {
      return state.aktien.User
    },
    getUserIsMod: (state) => {
      return (userId) => {
        return state.admin.includes(parseInt(userId))
      }
    }
  }
})