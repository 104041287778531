<template>
  <div>
    <span>
      Droppz
      <v-switch
        v-model="droppzFoggzToggle"
        :class="{'d-inline-flex ml-2 mr-n2': true, 'droppz-color': droppzFoggzToggle === 'droppz'}"
        color="primary"
        false-value="droppz"
        inset
        true-value="foggz"
        @change="updateChart()"
      />
      Foggz
    </span>
    <span v-if="droppzFoggzToggle === 'foggz'">
      <h1>
        <span v-show="parseInt(foggz.userFoggz.totaldiff) > 0">
          <v-icon color="green">mdi-arrow-up</v-icon>
        </span>
        <span v-show="parseInt(foggz.userFoggz.totaldiff) === 0">
          <v-icon color="orange">mdi-equal</v-icon>
        </span>
        <span v-show="parseInt(foggz.userFoggz.totaldiff) < 0">
          <v-icon color="red">mdi-arrow-down</v-icon>
        </span>
        Total Foggz: {{ foggz.userFoggz.totalfoggz }} <small>({{ foggz.userFoggz.totaldiff }})</small>
      </h1>
    </span>
    <span v-else>
      <h1>
        <span v-show="parseInt(droppz.userDroppz.totaldiff) > 0">
          <v-icon color="green">mdi-arrow-up</v-icon>
        </span>
        <span v-show="parseInt(droppz.userDroppz.totaldiff) === 0">
          <v-icon color="orange">mdi-equal</v-icon>
        </span>
        <span v-show="parseInt(droppz.userDroppz.totaldiff) < 0">
          <v-icon color="red">mdi-arrow-down</v-icon>
        </span>
        Total Droppz: {{ droppz.userDroppz.totaldroppz }} <small>({{ droppz.userDroppz.totaldiff }})</small>
      </h1>
      <h3>
        <span v-show="parseInt(droppz.userDroppz.jackdiff) > 0">
          <v-icon color="green">mdi-arrow-up</v-icon>
        </span>
        <span v-show="parseInt(droppz.userDroppz.jackdiff) === 0">
          <v-icon color="orange">mdi-equal</v-icon>
        </span>
        <span v-show="parseInt(droppz.userDroppz.jackdiff) < 0">
          <v-icon color="red">mdi-arrow-down</v-icon>
        </span>
        Jackpot: {{ droppz.userDroppz.jackpot }} <small>({{ droppz.userDroppz.jackdiff }})</small> /
        Aktien: {{ droppz.userDroppz.aktien }} /
        Lotto: {{ droppz.userDroppz.lotto }}
      </h3>
    </span>
    <v-data-table
      id="droppz"
      :footer-props="tableFooter"
      :headers="tableHeaders[droppzFoggzToggle]"
      :items="getSelectedTableData"
      :loading="loading"
      :search="tableSearch"
      class="mt-5 shaped"
      dense
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="tableSearch"
              class="mx-2"
              label="Suche"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-data-footer
              :items-per-page-options="tableFooter.itemsPerPageOptions"
              :items-per-page-text="tableFooter.itemsPerPageText"
              :options="options"
              :pagination="pagination"
              :show-current-page="true"
              :show-first-last-page="true"
              @update:options="updateOptions"
            ></v-data-footer>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(user, idx) in items" :key="idx">
            <td v-for="header in tableHeaders[droppzFoggzToggle]" :key="header.text">
              <!-- COPY TO CLIPBOARD -->
              <span v-if="header.text === 'Tendenz'">
              <span v-show="user.diff > 0">
                <v-icon color="green">mdi-arrow-up</v-icon>
              </span>
              <span v-show="user.diff === 0">
                <v-icon color="orange">mdi-equal</v-icon>
              </span>
              <span v-show="user.diff < 0">
                <v-icon color="red">mdi-arrow-down</v-icon>
              </span>
              {{ user.diff }} ({{ user.placediff }})
            </span>
              <!-- DEFAULT VALUE -->
              <span v-else>
              {{ getValue(user, header) }}
            </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div ref="historyChart" class="chartDiv"></div>
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { droppzStore } from '@/store/droppz'
import { foggzStore } from '@/store/foggz'

export default {
  name: 'DroppzOverview',
  setup() {
    const droppz = droppzStore()
    const foggz = foggzStore()
    return {
      droppz,
      foggz
    }
  },
  data() {
    return {
      loading: false,
      reloading: false,
      tableSearch: '',
      droppzFoggzToggle: 'droppz',
      tableHeaders: {
        droppz: [
          {
            text: '#',
            align: 'start',
            value: 'index'
          },
          {
            text: 'User',
            value: 'user'
          },
          {
            text: 'Droppz',
            value: 'droppz',
            align: 'start'
          },
          {
            text: 'Tendenz',
            value: 'diff'
          }
        ],
        foggz: [
          {
            text: '#',
            align: 'start',
            value: 'index'
          },
          {
            text: 'User',
            value: 'user'
          },
          {
            text: 'Foggz',
            value: 'foggz',
            align: 'start'
          },
          {
            text: 'Tendenz',
            value: 'diff'
          }
        ]
      },
      tableFooter: {
        itemsPerPageOptions: [25, 50, 100, 250],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: 'Einträge pro Seite'
      }
    }
  },
  computed: {
    getValue() {
      return (item, header) => {
        return item[header.value]
      }
    },
    getSelectedTableData() {
      if (this.droppzFoggzToggle === 'foggz') {
        return this.foggz.userFoggz.user
      }
      return this.droppz.userDroppz.user
    }
  },
  methods: {
    async requestData() {
      await this.droppz.fetchDroppzData()
      await this.foggz.fetchFoogzData()
      this.loading = false
    },
    createChart(retry = 0) {
      const chartDiv = this.$refs.historyChart
      // https://www.amcharts.com/docs/v5/concepts/colors-gradients-and-patterns/
      const chartRoot = am5.Root.new(chartDiv)
      chartRoot.setThemes([
        am5themes_Animated.new(chartRoot)
      ])
      chartRoot.interfaceColors.set('text', am5.color('#ffffff'))
      const chart = chartRoot.container.children.push(am5xy.XYChart.new(chartRoot, {
        cursorTooltipEnabled: true
      }))
      const cursor = chart.set('cursor', am5xy.XYCursor.new(chartRoot, {
        behavior: 'none'
      }))
      cursor.lineY.set('visible', false)
      const xAxis = chart.xAxes.push(am5xy.DateAxis.new(chartRoot, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: 'minute',
          count: 5
        },
        renderer: am5xy.AxisRendererX.new(chartRoot, {}),
        tooltip: am5.Tooltip.new(chartRoot, {})
      }))
      const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(chartRoot, {
        renderer: am5xy.AxisRendererY.new(chartRoot, { opposite: true }),
        tooltip: am5.Tooltip.new(chartRoot, {})
      }))
      const seriesChart = chart.series.push(am5xy.LineSeries.new(chartRoot, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        cursorTooltipEnabled: false,
        tooltip: am5.Tooltip.new(chartRoot, {
          labelText: '{valueY}'
        })
      }))
      seriesChart.strokes.template.setAll({
        strokeWidth: 3
      })
      chart.set('scrollbarX', am5.Scrollbar.new(chartRoot, {
        orientation: 'horizontal'
      }))

      seriesChart.data.setAll(this.droppz.chartDroppz)

      seriesChart.appear(1000, 200)
      chart.appear(1000, 200)

      this.historyChart = chartRoot
      this.seriesChart = seriesChart

      if (!this.historyChart && retry <= 6) {
        setTimeout(() => {
          retry++
          this.createChart(retry)
        }, 1000 * retry)
      }
    },
    destroyChart() {
      this.historyChart.dispose()
    },
    updateChart() {
      let chartData = this.droppz.chartDroppz
      if (this.droppzFoggzToggle === 'foggz') {
        chartData = this.foggz.chartFoggz
      }
      this.seriesChart.data.setAll(chartData)
    }
  },
  mounted() {
    this.$emit('updateMounted')
  },
  async created() {
    this.loading = true
    await this.requestData()
    await this.createChart()
    this.reloading = setInterval(async () => {
      this.loading = true
      await this.requestData()
      await this.updateChart()
    }, 1000 * 60 * 5)
  },
  beforeDestroy() {
    clearInterval(this.reloading)
    this.destroyChart()
  }
}
</script>

<style scoped>
.theme--dark.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #303030;
}

.chartDiv {
  bottom: 0;
  width: 100%;
  height: 500px;
}

.droppz-color.theme--dark.v-input--switch :deep(.v-input--switch__thumb),
.droppz-color.theme--dark.v-input--switch :deep(.v-input--switch__track) {
  color: #FFAB00 !important;
}
</style>