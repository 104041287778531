import { defineStore } from 'pinia'
import { sendToSocket } from "@/services/socketFunctions";

export const useSocketStore = defineStore('socketStore', {
  state: () => {
    return {
      jsonFiles: {},
      connected: false,
      // TARGETS
      cs_media: 'cs-media.www',
      cs_service: 'cs-service'
    }
  },
  actions: {
    setJSONFile(jsonStr, fileName) {
      let jsonData = {}
      if (jsonStr) {
        jsonData = JSON.parse(jsonStr)
      }
      this.jsonFiles[fileName] = jsonData
    },
    requestJSON(fileName) {
      this.jsonFiles[fileName] = {}
      sendToSocket(1, this.cs_media, 'media.getprivatjson', fileName)
    }
  },
  getters: {}
})