<template>
  <div>
    <v-row>
      <v-col cols="6">
        <h2>Solarenergie: {{ solarAktuell }}W</h2>
        <div ref="solar" class="chartDiv"></div>
      </v-col>
      <v-col cols="6">
        <h2 class="justify-center">Verbrauch: {{ usedAktuell }}W</h2>
        <div ref="used" class="chartDiv"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <h2>Akkustand: {{ socAktuell }}%</h2>
        <div ref="soc" class="chartDiv"></div>
      </v-col>
      <v-col cols="6">
        <h2>Akkuverbrauch: {{ chargeAktuell }}W</h2>
        <div ref="charge" class="chartDiv"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import {houseStore} from "@/store/house";

export default {
  name: "HouseData",
  setup() {
    const houseData = houseStore()
    return {
      houseData
    }
  },
  data() {
    return {
      reloading: false,
      charts: [],
      seriesCharts: []
    }
  },
  computed: {
    solarAktuell() {
      if (this.houseData.solarAktuell) {
        return this.houseData.solarAktuell.solar
      }
      return '-/-'
    },
    usedAktuell() {
      if (this.houseData.solarAktuell) {
        return this.houseData.solarAktuell.used
      }
      return '-/-'
    },
    socAktuell() {
      if (this.houseData.solarAktuell) {
        return this.houseData.solarAktuell.soc
      }
      return '-/-'
    },
    chargeAktuell() {
      if (this.houseData.solarAktuell) {
        return this.houseData.solarAktuell.charge
      }
      return '-/-'
    }
  },
  methods: {
    createCharts(retry = 0) {
      Object.keys(this.$refs).forEach((chartDivId) => {
        const chartDiv = this.$refs[chartDivId]
        // https://www.amcharts.com/docs/v5/concepts/colors-gradients-and-patterns/
        const chartRoot = am5.Root.new(chartDiv)
        chartRoot.setThemes([
          am5themes_Animated.new(chartRoot)
        ])
        chartRoot.interfaceColors.set("text", am5.color('#ffffff'))
      
        const chart = chartRoot.container.children.push(am5xy.XYChart.new(chartRoot, {
          cursorTooltipEnabled: true,
        }))
      
        const cursor = chart.set("cursor", am5xy.XYCursor.new(chartRoot, {
          behavior: "none"
        }))
        cursor.lineY.set("visible", false);
      
        const xAxis = chart.xAxes.push(am5xy.DateAxis.new(chartRoot, {
          maxDeviation: 0.2,
          baseInterval: {
            timeUnit: "minute",
            count: 1
          },
          renderer: am5xy.AxisRendererX.new(chartRoot, {}),
          tooltip: am5.Tooltip.new(chartRoot, {})
        }))
        const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(chartRoot, {
          renderer: am5xy.AxisRendererY.new(chartRoot, {opposite: true}),
          tooltip: am5.Tooltip.new(chartRoot, {})
        }))
      
        const seriesChart = chart.series.push(am5xy.LineSeries.new(chartRoot, {
          name: "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: chartDivId,
          valueXField: "date",
          cursorTooltipEnabled: false,
          tooltip: am5.Tooltip.new(chartRoot, {
            labelText: "{valueY}"
          }),
        }))
      
        seriesChart.strokes.template.setAll({
          strokeWidth: 3
        })
      
        chart.set("scrollbarX", am5.Scrollbar.new(chartRoot, {
          orientation: "horizontal"
        }))
      
        seriesChart.data.setAll(this.houseData.solarData)
      
        seriesChart.appear(1000, 200)
        chart.appear(1000, 200)
      
        this.charts.push(chartRoot)
        this.seriesCharts.push(seriesChart)
      })
      if (!this.charts.length && retry <= 6) {
        setTimeout(() => {
          retry++
          this.createCharts(retry)
        }, 1000 * retry)
      }
    },
    destroyCharts() {
      this.charts.forEach((chart) => {
        chart.dispose()
      })
    },
    updateCharts() {
      this.seriesCharts.forEach((chart) => {
        chart.data.setAll(this.houseData.solarData)
      })
    }
  },
  async created() {
    await this.houseData.fetchSolarData()
    await this.createCharts()
    this.reloading = setInterval(async () => {
      await this.houseData.fetchSolarData()
      await this.updateCharts()
    }, 1000 * 30)
  },
  beforeDestroy() {
    clearInterval(this.reloading)
    this.destroyCharts()
  }
}
</script>

<style scoped>
.chartDiv {
  border: 1px solid black;
  width: 100%;
  height: 400px;
}
</style>