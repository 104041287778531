<template>
  <v-card
    elevation="2"
    outlined
    shaped
    style="min-height: fit-content"
  >
    <v-card-title class="content-bg-info-text font-weight-bold">
      Trinkgeld an Floppz
    </v-card-title>
    <v-card-text class="mt-5 justify-center">
      <v-row justify="center">
        <v-col cols="12">
          <v-card
            color="bgInfo"
            shaped
          >
            <v-card-text>
              <strong>
                Das Trinkgeld ist eine Möglichkeit den Stream zusätzlich und freiwillig zu unterstützen.<br>
                Trinkgelder sind nicht Teil der Supporter Plattform (Foundation) und werden dort auch nicht angerechnet.
              </strong>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-show="successPaid || support.donationSuccess" justify="center">
        <v-col cols="12" lg="6" md="6" sm="12" xl="4" xs="12">
          <v-card
            color="floppzPrimary"
            outlined
            shaped
          >
            <v-card-title class="justify-center no-wrap">
              <h2>Danke für das Trinkgeld <strong>{{ donationUser }}</strong>!</h2>
            </v-card-title>
            <v-card-text class="text-center">
              <v-divider></v-divider>
              <v-avatar class="mt-5" size="128">
                <img
                  alt="Floppz"
                  src="/paypal_thx.png"
                >
              </v-avatar>
              <br>
              {{ support.donationSuccess }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-show="transactionError || transactionCancel || support.donationFailed" justify="center">
        <v-col cols="12" lg="6" md="6" sm="12" xl="4" xs="12">
          <v-card
            :color="transactionFailStatus"
            outlined
            shaped
          >
            <v-card-title
              v-if="transactionError"
              class="justify-center no-wrap"
            >
              <h2>{{ donationUser }} da ist wohl etwas schief gelaufen!</h2>
              <small>Bitte prüfe deinen PayPal account ob eine Transaktion stattgefunden hat und melde dich notfalls bei den mods!</small>
            </v-card-title>
            <v-card-title
              v-else-if="transactionCancel"
              class="justify-center no-wrap"
            >
              <h2>{{ donationUser }} deine Transaktion wurde erfolgreich abgebrochen.</h2>
            </v-card-title>
            <v-card-title
              v-else-if="support.donationFailed"
              class="justify-center no-wrap"
            >
              <h2>{{ donationUser }} da ist wohl etwas schief gelaufen!</h2>
              <small>Bitte prüfe dein Guthaben und melde dich notfalls bei den mods!</small>
            </v-card-title>
            <v-card-text v-if="support.donationSuccess" class="text-center">
              <v-divider></v-divider>
              <v-avatar class="mt-5" size="128">
                <img
                  alt="Floppz"
                  src="/paypal_error.png"
                >
              </v-avatar>
              <br>
              {{ support.donationSuccess }}
            </v-card-text>
            <v-card-text v-else class="text-center">
              <v-divider></v-divider>
              <v-avatar class="mt-5" size="128">
                <img
                  alt="Floppz"
                  src="/paypal_error.png"
                >
              </v-avatar>
              <br>
              {{ support.donationFailed }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="6" md="6" sm="12" xl="4" xs="12">
          <v-card
            color="bgThird"
            outlined
            shaped
          >
            <v-card-text class="mt-5">
              <v-text-field
                v-model="donationUser"
                append-icon="mdi-account"
                clearable
                dense
                hint="Wir empfehlen den echten Twitch Usernamen zu nutzen."
                label="Twitch Username"
              ></v-text-field>
              <v-checkbox
                v-model="donateAnon"
                class="mt-n4"
                label="Anonym spenden"
                @change="toggleAnonymous"
              />
              <v-text-field
                v-model="donationValue"
                :rules="valueRule"
                append-icon="mdi-currency-eur"
                class="mt-5"
                clearable
                dense
                label="Betrag"
                @blur="checkSetValue"
                @keypress="checkInput"
              ></v-text-field>
              <v-checkbox
                v-model="sportDonation"
                :class="{'colorActive': !sportDonationDisabled, 'colorDisabled': sportDonationDisabled}"
                :color="sportDonationStatus.color"
                :disabled="sportDonationDisabled"
                label="Sport Donation"
                @change="manualSportDonation"
              >
                <template #label>
                  <span v-if="sportDonationDisabled">
                    <s>Sport Donation</s>
                  </span>
                  <span v-else>
                    Sport Donation
                  </span>
                </template>
              </v-checkbox>
              <div class="mt-n5">
                <strong>{{ sportDonationHint }}</strong>
              </div>
              <div v-if="$route.query.debug === 's3cr3t'">
                <br>
                <pre style="color: #5bc0de"><u>SPORT DEBUG:</u><br>{{ support.sportButton }}
                </pre>
              </div>
              <v-textarea
                v-model="donationMessage"
                clearable
                counter
                label="TTS Nachricht"
                maxlength="160"
                placeholder="Gebe hier deine Nachricht ein (maximal 160 Zeichen)"
                rows="5"
              />
              <v-divider class="mt-5" />
              <v-alert
                v-show="lastSportDonation && !this.support.sportButton.status || lastSportDonation && !sportDonation"
                dense
                dismissible
                outlined
                type="error"
              >
                <strong>ACHTUNG</strong><br>
                Du wolltest eine <strong>Sport-Donation</strong>, jemand anderes war leider schneller und Sport wurde bei dir deaktiviert.<br>
                Dies ist nur ein Hinweis, dass du nicht "umsonst" dein Geld ausgibst.
              </v-alert>
              <v-row
                v-show="showPayPalLoader"
                class="pb-10 mt-5"
                justify="center"
              >
                <v-icon
                  class="load-animation pa-5"
                  size="100px"
                >
                  mdi-loading
                </v-icon>
              </v-row>
              <v-btn
                v-show="userDepositQualified && showPayPalButtons"
                :disabled="userDepositToLow"
                block
                class="mt-5"
                color="bgInfo"
                :large="$vuetify.breakpoint.smAndUp"
                @click="donateWithDeposit"
              >
                Guthaben verwenden
              </v-btn>
              <span v-show="userDepositQualified">
                Guthaben aktuell: <strong>{{ userDeposit }}€</strong>
              </span>
              <div
                v-show="showPayPalButtons"
                id="paypal-button-container"
                class="mt-5"
              />
              <v-alert
                :value="!socketActive"
                border="left"
                class="mt-5"
                color="floppzWarning"
              >
                <template #prepend>
                  <v-avatar
                    class="mr-2"
                    size="64"
                  >
                    <img
                      alt="Floppz"
                      src="/paypal_error.png"
                    >
                  </v-avatar>
                </template>
                <h3>Zahlungsoptionen deaktivert!</h3>
                Keine Verbindung zum Floppz-Netzwerk möglich.<br>
                Bitte Seite neu laden.<br>
                <small>Sollte das Probleme bestehen bleiben, bitte an die Mods wenden.</small>
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { loadScript } from '@paypal/paypal-js'
import { userStore } from '@/store/user'
import { useSupportStore } from '@/store/support'
import { useSocketStore } from '@/store/socketStore'

export default {
  name: 'PayPalDonation',
  setup() {
    return {
      user: userStore(),
      support: useSupportStore(),
      sockets: useSocketStore()
    }
  },
  data() {
    return {
      showPayPalLoader: true,
      successPaid: false,
      transactionCancel: false,
      transactionError: false,
      donationValue: 5,
      donationUser: '',
      donateAnon: false,
      donationMessage: '',
      sportDonation: false,
      lastSportDonation: false,
      valueRule: [value => parseInt(value) >= 5 || 'Mindestwert sind 5€']
    }
  },
  computed: {
    socketActive() {
      return this.sockets.connected
    },
    showPayPalButtons() {
      return (this.donationUser || this.donateAnon) && this.socketActive && this.donationValue > 4 && this.donationMessage.length <= 160
    },
    getUserName() {
      return this.user.userData.display_name || ''
    },
    sportDonationDisabled() {
      if (!this.support.sportButton.status || this.donationValue < 30) {
        this.toggleSportDonation(false)
        return true
      }
      return false
    },
    sportDonationHint() {
      if (!this.support.sportButton.status) {
        return this.support.sportButton.message
      }
      if (this.donationValue < 30) {
        return 'Betrag zu gering! (min. 30€)'
      }
      if (this.support.sportButton.status && this.sportDonation) {
        return 'Sport gewählt - Folterknecht tu dein Werk!'
      }
      return 'Sport nicht gewählt - Also wirklich nur Trinkgeld.'
    },
    sportDonationStatus() {
      if (this.sportDonation) {
        return { color: '#4caf50', icon: 'mdi-check', text: 'ausgewählt' }
      }
      return { color: '#f44336', icon: 'mdi-close', text: 'nicht ausgewählt' }
    },
    transactionFailStatus() {
      if (this.transactionError) {
        return 'floppzError'
      }
      if (this.transactionCancel) {
        return 'floppzWarning'
      }
      return 'floppzPrimary'
    },
    userDepositQualified() {
      return this.support.supportInfo.deposit >= 5
    },
    userDepositToLow() {
      return this.support.supportInfo.deposit < this.donationValue
    },
    userDeposit() {
      return this.support.supportInfo.deposit
    }
  },
  methods: {
    resetForm() {
      this.donationMessage = ''
      this.lastSportDonation = false
      this.sportDonation = false
    },
    toggleAnonymous(value) {
      if (value === true) { this.donationUser = '' }
      else { this.donationUser = this.getUserName }
    },
    manualSportDonation(value) {
      this.lastSportDonation = value
    },
    toggleSportDonation(value) {
      this.sportDonation = value
    },
    checkInput(event) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()
      if (!/^[0-9]*$/.test(expect)) {
        event.preventDefault()
      }
      else {
        return true
      }
    },
    checkSetValue() {
      if (parseInt(this.donationValue) > 1000) {
        this.donationValue = 1000
      }
    },
    createOrder(data, actions) {
      let paymentType = 'Donation Payment'
      this.successPaid = false
      this.transactionCancel = false
      this.transactionError = false
      if (this.sportDonation) {
        // setzt 5 minuten button cooldown (während transaktion)
        this.support.setSportDonationButtonCoolDown(1000 * 60 * 5, true)
        paymentType = 'Sport-Donation Payment'
      }
      return actions.order.create({
        purchase_units: [
          {
            description: paymentType,
            amount: {
              value: this.donationValue
            }
          }
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING'
        }
      })
    },
    async donateWithDeposit() {
      const donationPayload = {
        uid: this.user.userData.id || 0,
        iname: this.donationUser,
        value: this.donationValue,
        desc: this.donationMessage,
        useDeposit: true
      }
      if (this.sportDonation) {
        this.sportDonation = false
        await this.support.sendSportDonation(donationPayload)
        await this.support.getSportDonationButton(this.donationUser)
      }
      else {
        await this.support.sendDonation(donationPayload)
      }
      await this.support.getSupportInfo(this.user.userData.id)
      await this.resetForm()
    },
    onApprove(data, actions) {
      return actions.order.capture().then(async () => {
        this.successPaid = true
        const donationPayload = {
          uid: this.user.userData.id || 0,
          iname: this.donationUser,
          value: this.donationValue,
          desc: this.donationMessage,
          useDeposit: false  // set true if use deposit selected
        }
        if (this.sportDonation) {
          this.sportDonation = false
          await this.support.sendSportDonation(donationPayload)
          await this.support.getSportDonationButton(this.donationUser)
        }
        else {
          await this.support.sendDonation(donationPayload)
        }
        await this.support.getSupportInfo(this.user.userData.id)
        await this.resetForm()
      })
    },
    onCancel(data) {
      this.transactionCancel = true
      this.resetSportButton()
      console.info(data)
    },
    onError(err) {
      this.transactionError = true
      this.resetSportButton()
      console.error(err)
    },
    resetSportButton() {
      if (this.sportDonation) {
        // hebt vorher gesetzte sperre wieder auf
        this.support.setSportDonationButtonCoolDown(-1000 * 60 * 5)
      }
    }
  },
  mounted() {
    this.$emit('updateMounted')
  },
  beforeCreate() {
    this.showPayPalLoader = true
    const paypalOptions = {
      'client-id': process.env.VUE_APP_PAYPAL_ID,
      components: 'buttons',
      currency: 'EUR',
      'disable-funding': process.env.VUE_APP_PAYPAL_DISABLED_FUNDINGS
    }
    loadScript(paypalOptions).then((paypal) => {
      paypal
      .Buttons({
        createOrder: this.createOrder,
        onApprove: this.onApprove,
        onCancel: this.onCancel,
        onError: this.onError
      })
      .render('#paypal-button-container')
      this.showPayPalLoader = false
    })
  },
  async created() {
    if (this.user.userData.id === 0) {
      await this.user.fetchUserData()
    }
    this.donationUser = this.getUserName
    this.support.allowSportTimer = true
    this.support.setDonationSuccess(null)
    this.support.getSportDonationButton()
    const userId = this.user.userData.id || null
    if (userId) {
      this.support.getSupportInfo(userId)
    }
  },
  beforeDestroy() {
    this.support.allowSportTimer = false
    clearTimeout(this.support.sportInterval)
    this.support.sportInterval = null
  }
}
</script>

<style scoped>
.no-wrap {
  word-break: keep-all;
}

.content-bg-info-text {
  background-color: #404040;
}

.colorActive :deep(.v-input--switch__track),
.colorActive :deep(.v-input--switch__thumb),
.colorActive :deep(.theme--dark.v-icon) {
  color: v-bind('sportDonationStatus.color') !important;
}
</style>