<template>
  <div>
    <v-row>
      <v-col>
        <h1>
          Um ein wenig mehr Transparenz in unsere Spiele zu bringen...<br>
          hier eine kleine Auflistung, die hoffentlich die meisten Fragen klären kann.
        </h1>
      </v-col>
    </v-row>
  
    <v-row>
      <v-col>
        <v-expansion-panels accordion multiple>
          <v-expansion-panel>
            <v-expansion-panel-header color="bgSecondary">
              !slots
            </v-expansion-panel-header>
            <v-expansion-panel-content color="bgThird">
              <v-card-text>
                Auflistung der Symbole und der dazugehörigen Multiplikatoren
                <v-simple-table dense>
                  <thead>
                  <tr>
                    <th style="width: 30%">Symbol</th>
                    <th>Multiplikator</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>☂</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>☁</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>⭐</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>❤</td>
                    <td>10</td>
                  </tr>
                  <tr>
                    <td>☀</td>
                    <td>25</td>
                  </tr>
                  </tbody>
                </v-simple-table>
                <br>
                Gewinnbeispiele (basierend auf einem Einsatz von 500 Droppz)
                <v-simple-table dense>
                  <thead>
                  <tr>
                    <th>Symbolausgabe</th>
                    <th>Brechnung & Gewinn</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><strong>Doppel Beispiele (XX? oder ?XX)</strong></td>
                    <td><strong>Droppz * (Multiplikator / 5) = Gewinn in Droppz</strong></td>
                  </tr>
                  <tr>
                    <td>☂☂❓ oder ❓☂☂</td>
                    <td>500 * (1 / 5) = 100</td>
                  </tr>
                  <tr>
                    <td>☁☁❓ oder ❓☁☁</td>
                    <td>500 * (2 / 5) = 200</td>
                  </tr>
                  <tr>
                    <td>⭐⭐❓ oder ❓⭐⭐</td>
                    <td>500 * (5 / 5) = 500</td>
                  </tr>
                  <tr>
                    <td>❤❤❓ oder ❓❤❤</td>
                    <td>500 * (10 / 5) = 1000</td>
                  </tr>
                  <tr>
                    <td>☀☀❓ oder ❓☀☀</td>
                    <td>500 * (25 / 5) = 2500</td>
                  </tr>
                  <tr>
                    <td><strong>Doppel Beispiele (X?X)</strong></td>
                    <td><strong>Droppz * (Multiplikator / 2) = Gewinn in Droppz</strong></td>
                  </tr>
                  <tr>
                    <td>☂❓☂</td>
                    <td>500 * (1 / 2) = 250</td>
                  </tr>
                  <tr>
                    <td>☁❓☁</td>
                    <td>500 * (2 / 2) = 500</td>
                  </tr>
                  <tr>
                    <td>⭐❓⭐</td>
                    <td>500 * (5 / 2) = 1250</td>
                  </tr>
                  <tr>
                    <td>❤❓❤</td>
                    <td>500 * (10 / 2) = 2500</td>
                  </tr>
                  <tr>
                    <td>☀❓☀</td>
                    <td>500 * (25 / 2) = 6250</td>
                  </tr>
                  <tr>
                    <td><strong>Dreifach Beispiele (XXX)</strong></td>
                    <td><strong>Droppz * Multiplikator = Gewinn in Droppz</strong></td>
                  </tr>
                  <tr>
                    <td>☂☂☂</td>
                    <td>500 * 1 = 500</td>
                  </tr>
                  <tr>
                    <td>☁☁☁</td>
                    <td>500 * 2 = 1000</td>
                  </tr>
                  <tr>
                    <td>⭐⭐⭐</td>
                    <td>500 * 5 = 2500</td>
                  </tr>
                  <tr>
                    <td>❤❤❤</td>
                    <td>500 * 10 = 5000</td>
                  </tr>
                  <tr>
                    <td>☀☀☀</td>
                    <td>500 * 25 = 12.500</td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
  
          <v-expansion-panel>
            <v-expansion-panel-header color="bgSecondary">
              !lotto &nbsp;
              <span>
                <v-chip x-small>
                  4 aus 20
                </v-chip>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="bgThird">
              <v-card-text>
                <p>
                  Unser Lotto ist relativ kompakt gehalten.<br>
                  Die Gewinnchance liegt bei 1:4845 <small>(berechnet wie folgt: 20/4 x 19/3 x 18/2 x 17/1)</small>
                </p>
                <p>
                  Kosten für ein Lottolos sind 100 Droppz, die Ziehungen sind jeweils Dienstags und Donnerstags um 13:00Uhr.<br>
                  Gezogen werden die Zahlen auch, wenn Floppz nicht online sein sollte.
                </p>
                <p>Es kann pro Person nur 1 Los gemeldet werden (es kann aber beliebig oft kostenlos vor den Ziehung geändert werden).</p>
                <p>
                  1 Stunde vor der Ziehung (also 12:00Uhr) wird die Annahmestelle geschlossen.<br>
                  In dieser Zeit ist es nicht mehr möglich ein Los zu einzutragen oder zu ändern!
                </p>
                <p>
                  Es gibt 3 Gewinnklassen (da wir nur 4 Zahlen haben).<br>
                  Eine Auflistung der Gewinnklassen und der minimalen Ausschüttung findet ihr in der Tabelle.<br>
                </p>
                <v-simple-table dense>
                  <thead>
                  <tr>
                    <th style="width: 20%">Gewinnklasse</th>
                    <th>Beschreibung</th>
                    <th>Minimal Ausschüttung</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>GK 3</td>
                    <td>2 richtige</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>GK 2</td>
                    <td>3 richtige</td>
                    <td>250</td>
                  </tr>
                  <tr>
                    <td>GK 1</td>
                    <td>4 richtige</td>
                    <td>500</td>
                  </tr>
                  </tbody>
                </v-simple-table>
                <v-card color="bgInfo mt-5">
                  <v-card-text>
                    <h4 class="white--text">
                      Der Ausschüttungswert ist abhängig von der Spieleranzahl.<br>
                      Umso mehr Spieler, umso mehr wird ausgeschüttet.<br>
                      Umso mehr Gewinner, umso weniger wird ausgeschüttet in den einzelnen Gewinnklassen.
                    </h4>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
  
          <v-expansion-panel>
            <v-expansion-panel-header color="bgSecondary">
              !aktie
            </v-expansion-panel-header>
            <v-expansion-panel-content color="bgThird">
              <v-card-text>
                <p>
                  <strong>STONKS STONKS STONKS</strong>
                </p>
                <p>
                  Kauft Aktien (am besten, wenn sie günstig sind) und hofft darauf, dass der Wert steigt.<br>
                </p>
                <p>
                  Ihr könnt mehrere Aktien (einer Firma) in einer Transaktion kaufen oder verkaufen => z.B. <code>!aktie kauf MPX 50</code> für 50 Aktien von Metaplex.<br>
                  <strong>Pro Transaktion werden 100 Droppz Bearbeitungsgebühr von eurem Konto abgezogen.</strong><br>
                </p>
                <p>
                  Wenn die Aktien im richtigen Moment gekauft und wieder verkauft werden,<br>
                  könnt ihr somit "ohne zu arbeiten" euer Droppz-Konto füllen.<br>
                  Alternativ könnt ihr die Aktien auch als "Wertanlage" verwenden falls euch mal die Droppz ausgehen sollten.
                </p>
                <v-card color="bgInfo mt-5">
                  <v-card-text>
                    <h4 class="white--text">
                      Eine Information wie der Aktienkurs sich ändert,<br>
                      wird aus Gründen (Schutz vor Automation, sonstiger Betrug) nicht veröffentlicht.
                    </h4>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
  
          <v-expansion-panel>
            <v-expansion-panel-header color="bgSecondary">
              !boss
            </v-expansion-panel-header>
            <v-expansion-panel-content color="bgThird">
              <v-card-text>
                <p>
                  <strong>Boss Kämpfe</strong>
                </p>
                <p>Wir haben Boss Kämpfe gegen verschiedene Gegner.</p>
                <p><u>Die Bosse haben 4 Phasen</u><br>In der ersten Phase verstecken sich die Bosse und können angelockt werden.<br>In der zweiten Phase kann man sich zum Kampf eintragen.<br>In der dritten Phase kämpfen alle Helden gegen die Bosse.<br>In der vierten Phase erholen sich die Bosse wieder</p>
                <p>Zum Anlocken musst du ein gewissen Droppz einsatz geben und es beteht die Chance das es schief geht. z.B. <code>!locken 50</code></p>
                <p>Man kann sich selbst entscheiden, mit wieviel HP man sich beteiligt, aber je mehr Risiko man geht, umso besser ist der Gewinn, falls man überlebt.<br>Sollte man nicht überleben, gibt es nur einen kleinen Trostpreis. Wenn alle Helden sterben gibt es gar nichts.</p>
                <p>Beispiele: <code>!boss 500 </code> kostet dich 500 Droppz und du bekommst 500 HP.</p>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
          
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ChatGames",
  mounted() {
    this.$emit('updateMounted')
  }
}
</script>

<style scoped>
.theme--dark.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #303030;
}
</style>