<template>
  <v-app>
    <v-app-bar
      app
      color="floppzSecondary"
    >
      <v-app-bar-nav-icon
        class="d-flex d-lg-none"
        @click="showNavigation"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <floppz-navigation
      ref="navigation"
    ></floppz-navigation>
    <v-main
      v-resize="onResize"
      class="content-bg-primary scrollable pt-0 mt-16"
    >
      <v-container fluid>
        <router-view @updateMounted="scrollTop"></router-view>
      </v-container>
    </v-main>
    <v-snackbar
      v-for="(notification, index) in notificationStore.notifications"
      :key="index"
      v-model="notification.model"
      :color="notification.type"
      :vertical="true"
      right
      top
    >
      <h3>{{ notification.title }}</h3>
      <span>{{ notification.text }}</span>
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="notification.model = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import FloppzNavigation from '@/layout/FloppzNavigation'
import { userStore } from '@/store/user'
import { aktienStore } from '@/store/aktien'
import { socket } from '@/services/socket'
import { useNotificationStore } from '@/store/notifaction'

export default {
  name: 'FloppzPage',
  setup() {
    const user = userStore()
    const aktien = aktienStore()
    const notificationStore = useNotificationStore()
    return {
      user,
      aktien,
      notificationStore
    }
  },
  data() {
    return {}
  },
  components: {
    FloppzNavigation
  },
  methods: {
    scrollTop() {
      document.querySelector('.scrollable').scrollTo(0, 0)
    },
    onResize() {
      //64px is v-app-bar height in your case
      document.querySelector('.scrollable').style.height = (window.innerHeight - 64) + 'px'
    },
    showNavigation() {
      this.$refs.navigation.showNavigation(true)
    }
  },
  async created() {
    if ((location.protocol !== 'https:' || location.host.startsWith('www')) && process.env.NODE_ENV === 'production') {
      location.href = `https://${location.host.replace('www.', '')}/${location.hash}`
    }
    const hasCookie = this.user.setCookieData()
    if (hasCookie) {
      await this.user.fetchUserData()
    }
    await this.aktien.fetchAktienData()

    socket.connect()
  }
}
</script>

<style>
html {
  overflow-y: hidden !important;
}

.scrollable {
  overflow-y: scroll !important;
}

.content-bg-primary {
  background-color: #222222
}

.content-bg-secondary {
  background-color: #303030
}

.content-bg-error {
  background-color: #db5754
}

.content-bg-warning {
  background-color: #f39c12
}

.content-bg-normal {
  background-color: #2b4764
}

kbd {
  background-color: #404040 !important;
  color: #5bc0de !important;
}

pre {
  background-color: #404040 !important;
}

code {
  background-color: #404040 !important;
  color: #5bc0de !important;
}

.theme--dark.v-data-table thead {
  background-color: #2b4764;
}

.shaped {
  border-radius: 24px 4px !important;
}

.load-animation {
  display: inline-block;
  animation: load-animation 1s linear infinite;
}

@keyframes load-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #222222;
  border: 1px solid #393939;
}

::-webkit-scrollbar-thumb {
  background: #303030;
  border: solid 2px rgba(48, 48, 48, 0.5);
}

::-webkit-scrollbar-track:hover {
  background: #444444;
  border: 1px solid #393939;
}

::-webkit-scrollbar-thumb:hover {
  background: #707070;
  border: solid 2px rgba(48, 48, 48, 0.5);
}
</style>
