<template>
  <div>
    <v-expansion-panels
      v-model="expanded"
      :style="`max-width: ${maxWidth}`"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          Support
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- SUPPORT -->
          <foundation-support />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Foggz aufladen
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- FOGGZ -->
          <foggz-recharge />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Gifts / Geschenke (in Planung)
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Wie der Name schon vermuten lässt...<br>
          Wir möchten dir in Zukunft die Möglichkeit bieten, anderen Usern das Geschenk der Foundation zu machen.<br>
          Hierbei geht es wirklich nur um den Foundation Status, Foggz (siehe oben) kannst du über den Chat verschenken.<br>
          <br>
          Es wird natürlich auch hier verschiedenste Optionen geben<br>
          (die hier gelisteten sind noch <strong>NICHT</strong> fix, also nagelt mich nicht darauf fest!)<br>
          <ul>
            <li>Stufen 1 bis 4 verschenken</li>
            <li>Laufzeit 1 bis X Monate</li>
            <li>An einen ausgewählten User verschenken</li>
            <li>An einen random User verschenken (nur User der auch wirklich aktiv ist -> wir kümmern uns darum)</li>
            <li>An mehrere ausgewählte User verschenken</li>
            <li>An mehrere random User verschenken (nur User die auch wirklich aktiv sind -> wir kümmern uns darum)</li>
            <li>Sichtbar oder anonym verschenken (gut wir sehen es trotzdem, aber wir sagen es keinem... versprochen)</li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {userStore} from '@/store/user'
import {useSupportStore} from '@/store/support'
import FoundationSupport from '@/components/foundation/FoundationSupport.vue'
import FoggzRecharge from "@/components/foundation/FoggzRecharge.vue";
export default {
  name: "FoundationMain",
  components: {FoggzRecharge, FoundationSupport},
  setup() {
    return {
      user: userStore(),
      support: useSupportStore()
    }
  },
  props: {
    maxWidth: {
      type: String,
      default: "100%"
    }
  },
  data() {
    return {
      expanded: [0]
    }
  }
}
</script>

<style scoped>
.tab-items {
  border-left: 1px solid #3e3e3e;
  min-height: 200px;
}
</style>