<template>
  <v-dialog
    v-model="dialogShown"
    max-width="600px"
    @click:outside="cancelAction"
    @keydown.esc="cancelAction"
  >
  <v-card>
    <v-card-title>
      {{ dialogTitle }}
    </v-card-title>
    <v-card-text v-html="dialogText" />
    <v-card-actions>
      <v-btn
        color="orange darken-1"
        @click="cancelAction"
      >
        {{ buttonCancelText }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="green darken-1"
        @click="okAction"
      >
        {{ buttonOkText }}
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  data() {
    return {
      dialogShown: false,
      dialogTitle: 'Bist du dir sicher?',
      dialogText: 'Willst du das denn auch wirklich tun?',
      buttonOkText: 'Ja',
      buttonCancelText: 'Nein',
      // PROMISE
      resolvePromise: null,
      rejectPromise: null
    }
  },
  computed: {},
  methods: {
    resetDialog() {
      this.dialogShown = false
      this.rejectPromise = null
      this.resolvePromise = null
      this.dialogTitle = 'Bist du dir sicher?'
      this.dialogText = 'Willst du das denn auch wirklich tun?'
      this.buttonOkText = 'Ja'
      this.buttonCancelText = 'Nein'
    },
    showDialog(options = {}) {
      Object.keys(options).forEach(key => {
        this[key] = options[key]
      })
      this.dialogShown = true
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    okAction() {
      this.resolvePromise(true)
      this.resetDialog()
    },
    cancelAction() {
      this.resolvePromise(false)
      this.resetDialog()
    }
  }
}
</script>

<style scoped>
</style>