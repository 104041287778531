import { defineStore } from 'pinia'
import { sendToSocket } from '@/services/socketFunctions'

export const useSupportStore = defineStore('useSupportStore', {
  state: () => {
    return {
      supportInfo: {
        supporter: 0,
        supportdaysremain: 0,
        deposit: 0,
        nackenklatschen: 0
      },
      donationSuccess: '',
      donationFailed: '',
      supportSuccess: '',
      changeText: '',
      forceChangeText: '',
      sportButton: {},
      allowSportTimer: false,
      sportInterval: null,
      ignoreBroadcast: false,
      // foggz
      foggzSuccess: null,
      // REDIRECT
      redirList: {},
      // TARGETS
      cs_media: 'cs-media.www',
      cs_service: 'cs-service',
      ws_public: 'ws-public'
    }
  },
  actions: {
    // INFO TEXT
    getInfoText(supportLevel) {
      let getText = `support${supportLevel}.html`
      sendToSocket(1, this.cs_media, 'media.getprivattext', getText)
      getText = `force${getText}`
      sendToSocket(1, this.cs_media, 'media.getprivattext', getText)
    },
    setChangeText(socketData) {
      this.changeText = ''
      if (socketData) {
        this.changeText = socketData
      }
    },
    setForceChangeText(socketData) {
      this.forceChangeText = ''
      if (socketData) {
        this.forceChangeText = socketData
      }
    },
    // DONATION
    resetDonationData() {
      this.donationSuccess = ''
      this.donationFailed = ''
    },
    setDonationSuccess(successData) {
      this.resetDonationData()
      this.donationSuccess = successData || ''
    },
    setDonationFailed(failedData) {
      this.resetDonationData()
      this.donationFailed = failedData || ''
    },
    sendDonation(donationPayload) {
      sendToSocket(1, this.cs_service, 'service.adddonation', donationPayload)
    },
    getSportDonationButton(userName) {
      sendToSocket(1, this.cs_service, 'service.getsportbutton', userName)
    },
    setSportDonationButton(socketData) {
      if (this.ignoreBroadcast) {
        return
      }
      this.sportButton = {}
      if (!this.allowSportTimer) {
        return
      }
      if (socketData) {
        clearTimeout(this.sportInterval)
        this.sportButton = socketData
        this.sportInterval = setTimeout(() => {
          sendToSocket(1, this.cs_service, 'service.getsportbutton')
        }, socketData.timediff)
      }
    },
    sendSportDonation(donationPayload) {
      sendToSocket(1, this.cs_service, 'service.addsportdonation', donationPayload)
    },
    setSportDonationButtonCoolDown(coolDown, ignoreBroadcast = false) {
      this.ignoreBroadcast = ignoreBroadcast
      sendToSocket(1, this.cs_service, 'service.addsportcooldown', coolDown)
      setTimeout(() => {
        this.ignoreBroadcast = false
      }, coolDown + 10000)
    },
    // SUPPORT
    sendSupport(supportPayload) {
      sendToSocket(1, this.cs_service, 'service.addsupport', supportPayload)
    },
    setSupportSuccess(successData) {
      this.supportSuccess = ''
      if (successData) {
        this.supportSuccess = successData
      }
      setTimeout(() => {
        this.supportSuccess = ''
      }, 1000 * 5)
    },
    getSupportInfo(userId) {
      sendToSocket(1, this.cs_service, 'service.getuid', userId)
    },
    setUserSupport(socketData) {
      this.supportInfo = {}
      if (socketData) {
        this.supportInfo = socketData
      }
    },
    async updateSupportLevel(supportPayload, force = false) {
      let event = 'service.changesupportlevel'
      if (force) {
        event = 'service.forcechangesupport'
      }
      await sendToSocket(1, 'cs-service', event, supportPayload)
      await this.getSupportInfo(supportPayload.uid)
    },
    shareSupport(sharePayload) {
      sendToSocket(1, this.cs_service, 'service.sharesupport', sharePayload)
    },
    // FOGGZ
    setFoggz(foggzPayload) {
      sendToSocket(1, this.cs_service, 'service.addfoggz', foggzPayload.uid, foggzPayload.package)
    },
    setFoggzSuccess() {
      this.foggzSuccess = true
    },
    setFoggzFailed(failedData) {
      if (failedData && failedData.message) {
        this.foggzSuccess = failedData.message
      }
    },
    // REDIRECT
    getRedirList() {
      sendToSocket(1, this.ws_public, 'redir.list')
    },
    setRedirList(redirData) {
      this.redirList = []
      if (redirData) {
        this.redirList = redirData
      }
    },
    sendDelRedir(redirObj) {
      sendToSocket(1, this.ws_public, 'redir.del', redirObj)
    },
    sendNewRedir(redirObj) {
      sendToSocket(1, this.ws_public, 'redir.add', redirObj)
    },
    sendRenameRedir(redirObj) {
      sendToSocket(1, this.ws_public, 'redir.rename', redirObj)
    },
    sendChangeRedir(redirObj) {
      sendToSocket(1, this.ws_public, 'redir.change', redirObj)
    }
  },
  getters: {}
})