import {io} from 'socket.io-client'
import {socketAnyRepl, socketAnyRecv, socketAnyBcst} from "@/services/socketFunctions"
import {useSocketStore} from '@/store/socketStore'

export const baseConfig = {
  username: 'www-client',
  token: process.env.VUE_APP_SOCKET_TOKEN,
  loginname: 'www-client',
  authed: false
}
export const socket = io("wss://wspublic.floppz.de")

socket.on("connect", () => {})
socket.on("disconnect", () => {
  baseConfig.authed = false
  const sockets = useSocketStore()
  sockets.connected = false
})
socket.on('!hello!', () => {
  const now = new Date().valueOf()
  baseConfig.loginname = baseConfig.username+'.'+now
  socket.emit('!authname!', baseConfig.loginname)
});
socket.on('!welcome!', () => {
  socket.emit('!authpass!', baseConfig.token)
});
socket.on('!authpass!', () => {
  socket.emit('!auth!ack!')
  baseConfig.authed = true
  const sockets = useSocketStore()
  sockets.connected = true
});
socket.on('!noauth!', () => {});
socket.on("!ping!", (data) => {
  socket.emit('!pong!', data)
  // const loggerHost = 'cs-logger'
  // const socketMsg = `${baseConfig.loginname}: ping`
  // const socketTo = `>${baseConfig.username}>${loggerHost}>`
  // socket.emit(socketTo, 'log.append', 'level', socketMsg)
});
socket.on("!pong!", (data) => {
  const received = data
  const now = new Date().valueOf()
  socket.emit('!myping!', now - received)
  // const loggerHost = 'cs-logger'
  // const socketMsg = `${baseConfig.loginname}: pong`
  // const socketTo = `>${baseConfig.username}>${loggerHost}>`
  // socket.emit(socketTo, 'log.append', 'level', socketMsg)
});
socket.on("!myping!", () => {
  // const loggerHost = 'cs-logger'
  // const socketMsg = `${baseConfig.loginname}: myping ${data}`
  // const socketTo = `>${baseConfig.username}>${loggerHost}>`
  // socket.emit(socketTo, 'log.append', 'level', socketMsg)
});
socket.on('!tree!', () => {})
socket.on('!route!', () => {})

socket.onAny((dest, event, data, ...args) => {
  let re = />(.+)>(.+)>/
  let res = dest.match(re)
  if (res !== null) {
    // let from = res[1]
    let to = res[2]
    if (typeof socketAnyRecv === 'function') {
      if (to === baseConfig.loginname) socketAnyRecv(event, data, ...args)
    }
    return
  }
  re = /<(.+)<(.+)</
  res = dest.match(re)
  if (res !== null) {
    // let from = res[2]
    let to = res[1]
    if (typeof socketAnyRepl === 'function') {
      if (to === baseConfig.loginname) socketAnyRepl(event, data, ...args)
    }
    return
  }
  re = /%(.+)%/
  res = dest.match(re)
  if (res !== null) {
    // let from = res[1]
    if (typeof socketAnyBcst === 'function') {
      socketAnyBcst(event, data, ...args)
    }
  }
})
