import { defineStore } from 'pinia'
import axios from "axios"

const wsServer = process.env.VUE_APP_WS_SERVER
// const apiServer = process.env.VUE_APP_WEB_SERVER

export const useAdminStore = defineStore('adminStore', {
  state: () => {
    return {
      potData: []
    }
  },
  actions: {
    async fetchPotData() {
      const { data: jsonData } = await axios.get(`${wsServer}/json/pot.json`)
      if (jsonData) {
        this.potData = jsonData
      }
    }
  },
  getters: {
  }
})