<template>
  <div>
    <v-card :loading="processing" :color="error ? 'bgError' : ''" shaped>
      <v-card-title class="justify-center">{{ loginText }}</v-card-title>
      <v-card-text v-show="error" class="white--text">
        <v-divider></v-divider>
        <div class="mt-5">
        Der Login war nicht erfolgreich!<br>
        Bitte versuche es später erneut, sollte es dann immernoch nicht funktioneren wende dich an pleX.
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {userStore} from "@/store/user";

export default {
  name: "TwitchLoginHandler",
  setup() {
    const user = userStore()
    return {
      user
    }
  },
  data() {
    return {
      error: false,
      loginText: 'Logge ein...',
      processing: false
    }
  },
  methods: {
    async activateToken() {
      const code = this.$route.query.code
      if (code) {
        const activated = await this.user.activateToken(code)
        if (activated) {
          this.processing = false
          await this.$router.push({ name: 'UserProfile'})
        }
      }
      this.error = true
      this.loginText = 'Login nicht erfolgreich!'
    }
  },
  async created() {
    this.processing = true
    await this.activateToken()
  }
}
</script>

<style scoped>

</style>