<template>
  <v-card :max-width="maxWidth">
    <v-expansion-panels
      v-model="expanded"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          color="bgInfo"
        >
          Account Status
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Subscriber:
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-title>
                  <span v-html="getSubInfo"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-title>
                Droppz gesamt:
              </v-list-item-title>
              <v-list-item-title class="text-right">
                {{ userDroppz + aktienWert }} <img class="subemote" alt="subemote" :src="require('@/static/droppz.png')">
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle class="ml-5">
                Droppz:
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-right">
                {{ userDroppz }} <img class="subemote" alt="subemote" :src="require('@/static/droppz.png')">
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle class="ml-5">
                Aktien in Droppz:
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-right">
                {{ aktienWert }} <img class="subemote" alt="subemote" :src="require('@/static/droppz.png')">
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle class="ml-5">
                Aktien:<br>
                <v-list-item-subtitle class="text-right mt-n4">
                  <span v-html="userAktien"></span>
                </v-list-item-subtitle>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider />
            <v-list-item v-if="userLottoLos !== userDauerlos">
              <v-list-item-content>
                <v-list-item-title>
                  Aktuelles Los:
                </v-list-item-title>
                <v-list-item-subtitle>
                  Dauerlos:
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-title>
                  {{ userLottoLos }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ userDauerlos }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title>
                  Aktuelles & Dauerlos:
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-title>
                  {{ userLottoLos }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import {userStore} from '@/store/user'
import {droppzStore} from '@/store/droppz'
import {globStore} from '@/store/glob'
import {aktienStore} from '@/store/aktien'

export default {
  name: "UserStatus",
  setup() {
    return {
      user: userStore(),
      droppz: droppzStore(),
      glob: globStore(),
      aktien: aktienStore()
    }
  },
  props: {
    maxWidth: {
      type: String,
      default: "100%"
    }
  },
  data() {
    return {
      expanded: 0,
      aktienWert: 0
    }
  },
  computed: {
    userIsMod() {
      return this.glob.getUserIsMod(this.user.userData.id)
    },
    getSubInfo() {
      if (this.user.isSub) {
        let img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub0.png')}">`
        switch (this.user.isSub) {
          case 1:
            img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub1.png')}">`
            break
          case 2:
            img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub2.png')}">`
            break
          case 3:
            img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub3.png')}">`
            break
        }
        return `Stufe ${this.user.isSub} ${img}`
      }
      return 'Nein'
    },
    userDroppz() {
      if (this.droppz.userDroppz.user) {
        const userDroppz = this.droppz.getUserDroppz
        const usersDroppz = userDroppz.find(droppz => droppz.user.toLowerCase() === this.user.userData.display_name.toLowerCase())
        if (usersDroppz?.droppz) {
          return usersDroppz.droppz
        }
        return 0
      }
      return 'Droppz konnten nicht geladen werden'
    },
    userAktien() {
      this.resetAktienWert()
      if (this.glob.aktien && this.user.userData.id > 0) {
        const aktienArr = []
        const userAktien = this.glob.getUserAktien
        const latestAktien = this.aktien.aktienAktuell
        if (userAktien && latestAktien) {
          const usersAktie = userAktien[this.user.userData.id]
          if (usersAktie) {
            usersAktie.Aktie.forEach((aktien, index) => {
              if (aktien > 0) {
                const aktienAbk = this.aktien.aktienDesc[index].Abk
                const aktienValue = latestAktien['aktie' + (index + 1)] * aktien
                this.aktienWert += aktienValue
                aktienArr.push(`${aktienAbk}: ${aktien}`)
              }
            })
            if (aktienArr.length) {
              let returnStr = ''
              aktienArr.forEach((aktie, idx) => {
                returnStr += aktie
                if (idx % 2 === 0 && idx !== aktienArr.length - 1) {
                  returnStr += ' | '
                }
                if (idx % 2) {
                  returnStr += '<br>'
                }
              })
              return returnStr
            }
          }
        }
      }
      return 'Keine Aktien'
    },
    userLottoLos() {
      let lottoLos = 'Kein aktuelles Los'
      if (this.glob.lotto && this.user.userData.id > 0) {
        if (this.glob.lotto.NaechsteZiehung && this.glob.lotto.NaechsteZiehung.Lose && this.glob.lotto.NaechsteZiehung.Lose[this.user.userData.id]) {
          lottoLos = this.glob.lotto.NaechsteZiehung.Lose[this.user.userData.id]
        }
      }
      return lottoLos
    },
    userDauerlos() {
      let lottoLos = 'Kein Dauerlos'
      if (this.glob.lotto && this.user.userData.id > 0) {
        if (this.glob.lotto.Dauerlos && this.glob.lotto.Dauerlos.Lose && this.glob.lotto.Dauerlos.Lose[this.user.userData.id]) {
          lottoLos = `${this.glob.lotto.Dauerlos.Lose[this.user.userData.id]}`
        }
      }
      return lottoLos
    }
  },
  methods: {
    openSongCheck() {
      const userId = this.user.userData.id
      window.open(`http://cybex.has.nolife.org/floppz/songcheck.cgi?UID=${userId}`,'_blank')
    },
    resetAktienWert() {
      this.aktienWert = 0
    }
  },
  async created() {
    await this.glob.fetchGlobData()
    if (!this.aktien.aktienDesc.length) {
      await this.aktien.fetchAktienDesc()
      await this.aktien.fetchAktienValues()
    }
    if (!this.droppz.userDroppz.user) {
      await this.droppz.fetchUserDroppz()
    }
  }
}
// TODO:
// Mod userprofile check
// on load get => socket.emit('>...>cs-service', 'service.getuid', 12345)
// watchtime
// lastseen
// etc.
// using query paramter
// https://floppz.de/#/profile?user=smicer777
</script>

<style scoped>
:deep() .subemote {
  width: 12px;
}
.twitchLogin:hover {
  background-color: #772ce8;
  cursor: pointer;
}
</style>