<template>
  <div>
    <v-alert
      border="left"
      dense
      dismissible
      prominent
      :type="alert.type"
      :color="alert.type"
      v-model="alert.active"
      transition="scroll-y-transition"
    >
      <span v-html="alert.text"></span>
    </v-alert>
    <v-row>
      <v-col>
        <h1>Spiele <small style="font-size: 1rem">(gespielt / vorgeschlagen)</small></h1>
        <div style="font-size: 0.8rem">
          <v-row no-gutters>
            <v-col>
              <v-icon
                color="success"
                style="font-size: 1rem"
              >mdi-check-circle</v-icon> Wird ggf. mal gespielt
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-icon
                color="error"
                style="font-size: 1rem"
              >mdi-close-circle</v-icon> Wird definitiv nicht gespielt
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-icon
                color="primary"
                style="font-size: 1rem"
              >mdi-circle-slice-4</v-icon> Entscheidung noch nicht sicher
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col class="text-right">
        <v-card
          max-width="20rem"
          class="d-inline-block"
        >
          <v-card-text class="text-left">
            <v-row>
              <v-col cols="1" class="bgInfo">
                <v-icon
                  dense
                  left
                  class="info-box"
                >
                  mdi-information-outline
                </v-icon>
              </v-col>
              <v-col>
                Die Links zu MMOGA sind Affiliate Links.<br>
                Euch entstehen dadurch keine Mehrkosten, dafür unterstützt ihr Floppz beim Kauf über seinen Affiliate Link zusätzlich.
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :loading="tableLoading"
      :headers="tableHeaders"
      :footer-props="tableFooter"
      :search="tableSearch"
      :items="tableContent"
      class="mt-5 shaped"
      sort-by="last"
      sort-desc
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="tableSearch"
              label="Suche"
              class="ml-2 mt-5"
              clearable
              dense
            ></v-text-field>
          </v-col>
          <v-col>
            <v-tooltip top :disabled="user.userData.id > 0" color="info">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-on="on"
                  v-bind="attrs"
                  class="d-inline-block"
                >
                  <v-btn
                    small
                    color="primary"
                    @click="addGameDialog"
                    class="mt-5"
                    :disabled="user.userData.id <= 0"
                  >
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Spiel vorschlagen
                  </v-btn>
                </span>
              </template>
              <span>Du musst eingeloggt sein.</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              :show-current-page="true"
              :show-first-last-page="true"
              :items-per-page-options="tableFooter.itemsPerPageOptions"
              :items-per-page-text="tableFooter.itemsPerPageText"
              style="border-top: none"
            ></v-data-footer>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="(game, idx) in items" :key="idx">
          <td v-for="header in tableHeaders" :key="header.text">
            <!-- GAME TEXT -->
            <span v-if="header.text === 'Spiel'" v-html="game.name"></span>
            <!-- PLAYER TO ICONS -->
            <span v-else-if="header.text === 'Spieler'">
              <v-icon color="primary">
                mdi-{{ getPlayerIcon(game.player) }}
              </v-icon>
            </span>
             <!-- LINK TO ICONS -->
            <span v-else-if="header.text === 'Links'" v-html="linksToIcon(game)"></span>
            <!-- DATE TO READABLE -->
            <span v-else-if="header.text === 'Spielzeit'">
              {{ timeReadable(game) }} Stunden
            </span>
            <!-- MINUTES TO HOURS -->
            <span v-else-if="header.text === 'Zuletzt gespielt'">
              {{ dateReadable(game) }}
            </span>
            <!-- RATING ICONS -->
            <span v-else-if="header.text === 'Rating'">
              <span v-if="game.rating === 1">
                <v-icon color="success">mdi-check-circle</v-icon>
              </span>
              <span v-else-if="game.rating === 2">
                <v-icon color="error">mdi-close-circle</v-icon>
              </span>
              <span v-else-if="game.rating === 0">
                <v-icon color="primary">mdi-circle-slice-4</v-icon>
              </span>
              <span v-if="userIsFloppz">
                (
                <v-icon color="success" style="font-size: 1rem" @click="updateRating(game, 1)">
                  mdi-check-circle
                </v-icon>
                <v-icon color="error" style="font-size: 1rem" @click="updateRating(game, 2)">
                  mdi-close-circle
                </v-icon>
                <v-icon color="primary" style="font-size: 1rem" @click="updateRating(game, 0)">
                  mdi-circle-slice-4
                </v-icon>
                )
              </span>
            </span>
            <!-- COMMENT -->
            <span v-else-if="header.text === 'Kommentar'">
              <v-tooltip
                bottom
                :disabled="!game.comment"
                color="floppzSecondary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-on="on"
                    v-bind="attrs"
                  >
                  {{ truncateValue(game, header) }}
                  </span>
                </template>
                <span v-html="game.comment?.replace(/\n/g,'<br>')"></span>
              </v-tooltip>
              &nbsp;
              <span v-if="userIsFloppz">
                <v-btn
                  x-small
                  color="primary"
                  @click="editComment(game)"
                >
                  <v-icon small left>mdi-comment-text-outline</v-icon>
                  Edit
                </v-btn>
              </span>
            </span>
            <!-- MOD EDIT BUTTON -->
            <span v-else-if="header.text === 'Edit Game'">
              <v-btn
                x-small
                color="primary"
                @click="editGame(game)"
              >
                <v-icon small left>mdi-text-box-edit-outline</v-icon>
                Edit
              </v-btn>
            </span>
            <!-- DEFAULT VALUE -->
            <span v-else>
              {{ getValue(game, header) }}
            </span>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
    <!-- RATE DIALOG -->
    <v-dialog
      v-model="commentDialog"
      width="500"
      @click:outside="cancelComment"
      @keydown.esc="cancelComment"
    >
      <v-card>
        <v-card-title>
          Kommentar für {{ commentGameObj.name }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-textarea
            rows="2"
            label="Kommentar"
            auto-grow
            v-model="commentGameObj.comment"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="success"
          text
          @click="saveComment"
          >
            Speichern
          </v-btn>
          <v-btn
            color="warning"
            text
            @click="cancelComment"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDIT GAME DIALOG -->
    <v-dialog
      v-model="editGameDialog"
      width="800"
      @click:outside="cancelEdit"
      @keydown.esc="cancelEdit"
    >
      <v-card>
        <v-card-title>
          Edit für {{ editGameObj.name }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            :rules="gameNameRules"
            label="Spielname"
            v-model="editGameObj.name"
          ></v-text-field>
          <v-autocomplete
            clearable
            label="Single/Multiplayer"
            :items="gamePlayerTypes"
            item-text="typeText"
            item-value="typeId"
            v-model="editGameObj.player"
          ></v-autocomplete>
          <v-textarea
            rows="3"
            label="Links"
            auto-grow
            :value="linksToTextarea(editGameObj.links)"
            @change="updateGameLinks"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="saveEdit"
            :disabled="nameIsEmpty"
          >
            Speichern
          </v-btn>
          <v-btn
            color="warning"
            text
            @click="cancelEdit"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ADD GAME DIALOG -->
    <add-game
      :game-list="tableContent"
      :is-mod="userIsMod"
      @add:game="addNewGame"
      ref="add_game_dialog"
    ></add-game>
  </div>
</template>

<script>
import axios from "axios";
import {userStore} from "@/store/user";
import {globStore} from "@/store/glob";
import AddGame from "@/components/played_games/AddGame.vue";

const webServer = process.env.VUE_APP_WEB_SERVER
const apiEndpoint = process.env.VUE_APP_API_SERVER

export default {
  name: "PlayedGames",
  components: {AddGame},
  setup() {
    const user = userStore()
    const glob = globStore()
    return {
      user,
      glob
    }
  },
  data() {
    return {
      gameNameRules: [v => v && v.trim().length > 0 || 'Spielename darf nicht leer sein!'],
      editGameDialog: false,
      editGameObj: {},
      editGameObjOrigin: {},
      commentGameOrigin: '',
      commentDialog: false,
      commentGameObj: {},
      tableLoading: false,
      tableSearch: '',
      tableHeaders: [
        {
          text: '#',
          value: 'index',
          align: 'start'
        },
        {
          text: 'Spiel',
          value: 'name'
        },
        {
          text: 'Spieler',
          value: 'player'
        },
        {
          text: 'Vorgeschlagen von',
          value: 'user'
        },
        {
          text: 'Vorgeschlagen am',
          value: 'submitted'
        },
        {
          text: 'Spielzeit',
          value: 'minutes'
        },
        {
          text: 'Zuletzt gespielt',
          value: 'last'
        },
        {
          text: 'Rating',
          value: 'rating'
        },
        {
          text: 'Kommentar',
          value: 'comment'
        },
        {
          text: 'Links',
          value: 'links'
        }
      ],
      tableFooter: {
        itemsPerPageOptions: [25, 50, 100, -1],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: 'Einträge pro Seite'
      },
      tableContent: [],
      alert: {
        active: false,
        type: 'success',
        color: 'success',
        text: 'erfolgreich'
      },
      gamePlayerTypes: [
        {typeId: 1, typeText: 'Singleplayer'},
        {typeId: 2, typeText: 'Multiplayer'}
      ]
    }
  },
  computed: {
    userIsFloppz() {
      return this.user.userData.id === "16362402"
    },
    userIsMod() {
      return this.glob.getUserIsMod(this.user.userData.id)
    },
    truncateValue() {
      return (item, header) => {
        let value = this.getValue(item, header)
        return value.length > 20 ? `${value.substr(0,20)}...` : value
      }
    },
    getValue() {
      return (item, header) => {
        return item[header.value] ? item[header.value] : '-'
      }
    },
    timeReadable() {
      return (item) => {
        const time = item.minutes ? parseInt(item.minutes) : 0
        return Math.floor(time / 60)
      }
    },
    dateReadable() {
      return (item) => {
        let date = item.last
        if (date) {
          const dateObj = new Date(date)
          return dateObj.toLocaleTimeString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric' })
        }
        return '-'
      }
    },
    linksToIcon() {
      return (item) => {
        const linksArray = []
        item.links.forEach(link => {
          let linkHtml = `<a href="${link}" target="_blank">`
          if (link.match(/mmoga.de/gi)) {
            linkHtml += `<img class="img-link" alt="mmoga" src="${require('@/static/mmoga.png')}">`
          } else if (link.match(/steampowered.com/gi)) {
            linkHtml += `<img class="img-link" alt="steam" src="${require('@/static/steam.png')}">`
          } else if (link.match(/epicgames.com/gi)) {
            linkHtml += `<img class="img-link" alt="steam" src="${require('@/static/epic.png')}">`
          } else if (link.match(/gog.com/gi)) {
            linkHtml += `<img class="img-link" alt="steam" src="${require('@/static/gog.png')}">`
          } else {
            linkHtml += `<img class="img-link" alt="link" src="${require('@/static/link.png')}">`
          }
          linkHtml += `</a>`
          linksArray.push(linkHtml)
        })
        return linksArray.join('')
      }
    },
    getPlayerIcon() {
      return (item) => {
        if (item === 1) return 'account'
        else if (item === 2) return 'account-group'
        return 'account-question'
      }
    },
    linksToTextarea() {
      return (links) => {
        if (links) {
          return links.join('\n')
        }
        return ''
      }
    },
    nameIsEmpty() {
      if (this.editGameObj.name) {
        return this.editGameObj.name.trim().length <= 0
      }
      return true
    }
  },
  methods: {
    triggerAlert(alertObj) {
      clearTimeout((this.alert.timeout))
      this.alert = alertObj
      this.alert.timeout = setTimeout(() => {
        this.alert.active = false
      }, 3000)
    },
    updateGameLinks(value) {
      this.editGameObj.links = value.split('\n')
    },
    checkMMOGALinks(linkArr) {
      const newLinkArr = []
      linkArr.forEach(link => {
        if (link.match('mmoga')) {
          const splitLink = link.split('?')
          link = splitLink[0] + '?ref=57027&Partner=floppz'
        }
        if (link.trim()) newLinkArr.push(link)
      })
      return newLinkArr
    },
    editGame(gameObj) {
      this.editGameObjOrigin = gameObj
      this.editGameObj = {...gameObj}
      this.editGameDialog = true
    },
    async saveEdit() {
      await this.changeGame(this.editGameObj)
      this.editGameObj = {}
      this.editGameDialog = false
    },
    cancelEdit() {
      this.editGameObjOrigin = {}
      this.editGameObj = {}
      this.editGameDialog = false
    },
    editComment(gameObj) {
      this.commentGameObj = gameObj
      this.commentGameOrigin = gameObj.comment
      this.commentDialog = true
    },
    async saveComment() {
      await this.updateGame(this.commentGameObj)
      this.commentGameObj = {}
      this.commentDialog = false
    },
    cancelComment() {
      this.commentGameObj.comment = this.commentGameOrigin
      this.commentGameObj = {}
      this.commentGameOrigin = ''
      this.commentDialog = false
    },
    async updateRating(gameObj, rating) {
      gameObj.rating = rating
      await this.updateGame(gameObj)
        .then(async () => {
          const tableData = [...this.tableContent]
          await this.$nextTick()
          this.tableContent = [...tableData]
        })
        .catch(() => {
          this.getGamesList()
        })
    },
    async getGamesList() {
      const { data: gameData } = await axios.get(`${webServer}/json/games.json`)
      if (gameData.length) {
        this.tableContent = []
        gameData.forEach((game, index) => {
          if (game.name) {
            game.index = index + 1
            this.tableContent.push(game)
          }
        })
      }
      this.tableLoading = false
    },
    async updateGame(gameObj) {
      if (!this.userIsFloppz) {
        this.$router.go(0)
        return
      }
      const updateObj = {
        name: gameObj.name,
        comment: gameObj.comment,
        rating: gameObj.rating
      }
      await axios.post(`${apiEndpoint}/games/update`, updateObj)
        .then(() => {
          this.triggerAlert({
            active: true,
            type: 'success',
            color: 'success',
            text: `<strong>${gameObj.name}</strong> erfolgreich aktualisiert`
          })
        })
        .catch(() => {
          this.triggerAlert({
            active: true,
            type: 'error',
            color: 'error',
            text: `Fehler beim Aktualisieren von  <strong>${updateObj.name}</strong>`
          })
          this.getGamesList()
        })
    },
    async changeGame(gameObj) {
      if (!this.userIsMod) {
        this.$router.go(0)
        return
      }
      gameObj.links = this.checkMMOGALinks(gameObj.links)
      const updateObj = {
        name: gameObj.name,
        links: gameObj.links,
        player: gameObj.player || null
      }
      this.editGameObjOrigin.name = gameObj.name
      this.editGameObjOrigin.links = gameObj.links
      this.editGameObjOrigin.player = gameObj.player
      await axios.post(`${apiEndpoint}/games/change`, updateObj)
        .then(() => {
          this.triggerAlert({
            active: true,
            type: 'success',
            color: 'success',
            text: `<strong>${gameObj.name}</strong> erfolgreich aktualisiert`
          })
        })
        .catch(() => {
          this.triggerAlert({
            active: true,
            type: 'error',
            color: 'error',
            text: `Fehler beim Aktualisieren von  <strong>${updateObj.name}</strong>`
          })
          this.getGamesList()
        })
    },
    addGameDialog() {
      this.$refs.add_game_dialog.addGameDialog = true
    },
    async addNewGame(gameObj) {
      gameObj.links = this.checkMMOGALinks([gameObj.link])
      delete gameObj.link
      if (!gameObj.user || !gameObj.user.trim()) {
        gameObj.user = this.user.userData.display_name
      }
      gameObj.minutes = 0
      gameObj.last = ''
      gameObj.submitted = new Date().toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric' })
      await axios.post(`${apiEndpoint}/games/add`, gameObj)
        .then(() => {
          this.triggerAlert({
            active: true,
            type: 'success',
            color: 'success',
            text: `<strong>${gameObj.name}</strong> erfolgreich eingetragen`
          })
        })
        .catch(() => {
          this.triggerAlert({
            active: true,
            type: 'error',
            color: 'error',
            text: `Fehler beim Eintragen von  <strong>${gameObj.name}</strong>`
          })
        })
      await this.getGamesList()
    }
  },
  mounted() {
    this.$emit('updateMounted')
  },
  async created() {
    await this.glob.fetchGlobData()
    this.tableLoading = true
    if (this.user.userData.id === 0) {
      await this.user.fetchUserData()
    }
    if (this.userIsMod) {
      this.tableHeaders.push({
        text: 'Edit Game',
        value: ''
      })
    }
    await this.getGamesList()
  },
}
</script>

<style scoped>
.info-box {
    margin-left: -.6rem;
}
:deep() .img-link {
  margin-top: 6px;
  margin-right: 14px;
  width: 26px !important;
}
a {
  text-decoration: none !important;
}
.theme--dark.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #303030;
}
.truncate {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.v-tooltip__content {
  opacity: 1 !important;
}
</style>