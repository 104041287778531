<template>
  <v-card :max-width="maxWidth">
    <v-expansion-panels
      v-model="expanded"
      readonly
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          hide-actions
          color="bgInfo"
        >
          Konto aufladen
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-alert
            v-model="successPaid"
            border="left"
            color="floppzPrimary"
            dismissible
            class="mt-5"
          >
            <template #prepend>
              <v-avatar
                size="64"
                class="mr-2"
              >
                <img
                  alt="Floppz"
                  src="/paypal_thx.png"
                >
              </v-avatar>
            </template>
            <span v-if="!support.supportSuccess">
              PayPal Vorgang erfolgreich abgeschlossen.
              <br>
              Verarbeite Zahlungsdaten.
            </span>
            <span v-else>
              PayPal Vorgang erfolgreich abgeschlossen.
              <br>
              Zahlungsdaten erfolgreich verarbeitet.
            </span>
          </v-alert>
          <v-alert
            v-model="transactionIssue"
            border="left"
            :color="transactionFailStatus"
            dismissible
            class="mt-5"
          >
            <template #prepend>
              <v-avatar
                size="64"
                class="mr-2"
              >
                <img
                  alt="Floppz"
                  src="/paypal_error.png"
                >
              </v-avatar>
            </template>
            <span v-if="transactionError">
              <strong>Fehler bei der Transaktion!</strong><br>
              <small>Bitte prüfe deinen PayPal account ob eine Transaktion stattgefunden hat und melde dich notfalls bei den mods!</small>
            </span>
            <span v-else-if="transactionCancel">
              <strong>Transaktion wurde abgebrochen.</strong>
            </span>
            <span v-else>
              <strong>
                Unbekannter Fehler in der Transaktion!
                <br>
                Bitte wende dich an die pleX oder CyBex.
              </strong>
            </span>
          </v-alert>
          <div class="pt-1 text-center">
            <v-text-field
              dense
              clearable
              label="Betrag"
              append-icon="mdi-currency-eur"
              v-model="donationValue"
              :rules="valueRule"
              @keypress="checkInput"
              @blur="checkSetValue"
              class="mt-5"
            />
            <v-row
              v-show="showPayPalLoader"
              justify="center"
              class="pb-10"
            >
              <v-icon
                class="load-animation pa-5"
                size="100px"
              >
                mdi-loading
              </v-icon>
            </v-row>
            <div
              v-show="showPayPalButtons"
              id="paypal-support-button-container"
            />
            <v-alert
              :value="!socketActive"
              border="left"
              color="floppzWarning"
              class="mt-5"
            >
              <template #prepend>
                <v-avatar
                  size="64"
                  class="mr-2"
                >
                  <img
                    alt="Floppz"
                    src="/paypal_error.png"
                  >
                </v-avatar>
              </template>
              <h3>Zahlungsoptionen deaktivert!</h3>
              Keine Verbindung zum Floppz-Netzwerk möglich.<br>
              Bitte Seite neu laden.<br>
              <small>Sollte das Probleme bestehen bleiben, bitte an die Mods wenden.</small>
            </v-alert>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import {loadScript} from '@paypal/paypal-js'
import {userStore} from '@/store/user'
import {useSupportStore} from '@/store/support'
import {useSocketStore} from '@/store/socketStore'

export default {
  name: "FoundationPayPal",
  props: {
    maxWidth: {
      type: String,
      default: "100%"
    }
  },
  setup() {
    return {
      user: userStore(),
      support: useSupportStore(),
      sockets: useSocketStore()
    }
  },
  data() {
    return {
      expanded: 0,
      showPayPalLoader: true,
      successPaid: false,
      transactionCancel: false,
      transactionError: false,
      donationValue: 5,
      valueRule: [value => parseInt(value) >= 5 || 'Mindestwert sind 5€'],
      paypalOptions: {
        'client-id': process.env.VUE_APP_PAYPAL_ID,
        components: 'buttons',
        currency: 'EUR',
        'disable-funding': process.env.VUE_APP_PAYPAL_DISABLED_FUNDINGS
      }
    }
  },
  computed: {
    socketActive() {
      return this.sockets.connected
    },
    showPayPalButtons() {
      return this.socketActive && this.donationValue > 4
    },
    transactionFailStatus() {
      if (this.transactionError) return 'floppzError'
      if (this.transactionCancel) return 'floppzWarning'
      return 'floppzError'
    },
    transactionIssue: {
      set() {
        this.transactionError = false
        this.transactionCancel = false
      },
      get() {
        return this.transactionError || this.transactionCancel
      }
    }
  },
  methods: {
    checkInput(event) {
      event = event ? event : window.event;
      let expect = event.target.value.toString() + event.key.toString()
      if (!/^[0-9]*$/.test(expect)) {
        event.preventDefault()
      } else {
        return true
      }
    },
    checkSetValue() {
      if (parseInt(this.donationValue) > 1000) this.donationValue = 1000
    },
    createOrder(data, actions) {
      this.successPaid = false
      this.transactionCancel = false
      this.transactionError = false
      return actions.order.create({
        purchase_units: [{
          description: "Foundation Payment",
          amount: {
            value: this.donationValue
          },
        }],
        application_context: {
          shipping_preference: 'NO_SHIPPING'
        }
      })
    },
    onApprove(data, actions) {
      return actions.order.capture().then(async () => {
        this.successPaid = true
        const supportPayload = {
          uid: this.user.userData.id || 0,
          value: this.donationValue
        }
        await this.support.sendSupport(supportPayload)
        await this.support.getSupportInfo(this.user.userData.id)
      })
    },
    onCancel(data) {
      this.transactionCancel = true
      console.info(data)
    },
    onError(err) {
      this.transactionError = true
      console.error(err)
    },
    renderPayPalButtons() {
      loadScript(this.paypalOptions).then((paypal) => {
        paypal
          .Buttons({
            createOrder: this.createOrder,
            onApprove: this.onApprove,
            onCancel: this.onCancel,
            onError: this.onError
          })
          .render('#paypal-support-button-container')
        this.showPayPalLoader = false
      })
    },
  },
  beforeCreate() {
    this.showPayPalLoader = true
  },
  async created() {
    await this.support.getSupportInfo(this.user.userData.id)
    await this.renderPayPalButtons()
  }
}
</script>

<style scoped>
.content-bg-info-text {
  background-color: #404040;
}
</style>