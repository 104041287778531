import { render, staticRenderFns } from "./FoundationStatus.vue?vue&type=template&id=23903947&scoped=true&"
import script from "./FoundationStatus.vue?vue&type=script&lang=js&"
export * from "./FoundationStatus.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23903947",
  null
  
)

export default component.exports