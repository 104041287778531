<template>
  <div>
    <v-row>
      <v-col xs="12" sm="6" md="4" cols="12" v-for="(aktie, idx) in aktien.aktienDesc" :key="idx">
        <v-card shaped color="bgSecondary" style="height: 100%">
          <v-card-title><h2>{{ aktie.Name }} <small>({{ aktie.Abk }})</small></h2></v-card-title>
          <v-card-subtitle v-html="aktie.Beschreibung" class="fixed-subtitle"></v-card-subtitle>
          <v-card-text>
            <v-divider></v-divider>
            Aktueller Tageswert: <strong>{{ aktieTageswert(idx) }}</strong><br>
            Änderung zum Vortag: <strong>{{ aktieZumVortag(idx) }}</strong><br>
            Im Verkehr: <strong>{{ aktieImVerkehr(idx) }}</strong><br>
            <v-divider></v-divider>
            <div :ref="aktieChartRef(idx)" class="chartDiv"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {aktienStore} from "@/store/aktien";

export default {
  name: "AktienOverview",
  setup() {
    const aktien = aktienStore()
    return {
      aktien
    }
  },
  data() {
    return {
      charts: []
    }
  },
  computed: {
    aktieChartRef() {
      return (aktieIndex) => {
        return `aktie${aktieIndex + 1}`
      }
    },
    aktieTageswert() {
      return (aktieIndex) => {
        if (!this.aktien.aktienValues.length) {
          return '-/-'
        }
        const aktie = `aktie${aktieIndex + 1}`
        return this.aktien.aktienAktuell[aktie]
      }
    },
    aktieZumVortag() {
      return (aktieIndex) => {
        if (!this.aktien.aktienValues.length) {
          return '-/-'
        }
        const aktuell = this.aktieTageswert(aktieIndex)
        const aktie = `aktie${aktieIndex + 1}`
        const vortag = this.aktien.aktienVortag[aktie]
        let wertZumVortag = aktuell - vortag
        if (wertZumVortag > 0) {
          return `+${wertZumVortag}`
        }
        return wertZumVortag
      }
    },
    aktieImVerkehr() {
      return (aktienIndex) => {
        if (!Object.keys(this.aktien.aktienState).length) {
          return '-/-'
        }
        const aktienInUse = this.aktien.aktienState.InUse[aktienIndex]
        const aktienTotal = this.aktien.aktienState.Total[aktienIndex]
        const verkehrProzent = (aktienInUse * 100) / aktienTotal
        return `${verkehrProzent.toFixed(2)}%`
      }
    }
  },
  methods: {
    async checkAktien() {
      if (!this.aktien.hasAktien) {
        await this.aktien.fetchAktienData()
      }
    },
    createCharts(retry = 0) {
      Object.keys(this.$refs).forEach((chartDivId) => {
        const chartDiv = this.$refs[chartDivId][0]
        // https://www.amcharts.com/docs/v5/concepts/colors-gradients-and-patterns/
        const chartRoot = am5.Root.new(chartDiv)
        chartRoot.setThemes([
          am5themes_Animated.new(chartRoot)
        ])
        chartRoot.interfaceColors.set("text", am5.color('#ffffff'))
        
        const chart = chartRoot.container.children.push(am5xy.XYChart.new(chartRoot, {
            cursorTooltipEnabled: true,
        }))
        
        const cursor = chart.set("cursor", am5xy.XYCursor.new(chartRoot, {
          behavior: "none"
        }))
        cursor.lineY.set("visible", false);
  
        const xAxis = chart.xAxes.push(am5xy.DateAxis.new(chartRoot, {
          maxDeviation: 0.2,
          baseInterval: {
            timeUnit: "day",
            count: 1
          },
          renderer: am5xy.AxisRendererX.new(chartRoot, {}),
          tooltip: am5.Tooltip.new(chartRoot, {})
        }))
        const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(chartRoot, {
          renderer: am5xy.AxisRendererY.new(chartRoot, {opposite: true}),
          tooltip: am5.Tooltip.new(chartRoot, {})
        }))
  
        const seriesChart = chart.series.push(am5xy.LineSeries.new(chartRoot, {
          name: "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: chartDivId,
          valueXField: "date",
          cursorTooltipEnabled: false,
          tooltip: am5.Tooltip.new(chartRoot, {
            labelText: "{valueY}"
          }),
        }))
  
        seriesChart.strokes.template.setAll({
          strokeWidth: 3
        })
  
        chart.set("scrollbarX", am5.Scrollbar.new(chartRoot, {
          orientation: "horizontal"
        }))
        
        seriesChart.data.setAll(this.aktien.aktienValues)
        
        seriesChart.appear(1000, 200)
        chart.appear(1000, 200)
        
        this.charts.push(chartRoot)
      })
      if (!this.charts.length && retry <= 6) {
        setTimeout(() => {
          retry++
          this.createCharts(retry)
        }, 1000 * retry)
      }
    },
    destroyCharts() {
      this.charts.forEach((chart) => {
        chart.dispose()
      })
    },
  },
  mounted() {
    this.$emit('updateMounted')
  },
  async created() {
    await this.checkAktien()
    await this.createCharts()
  },
  beforeDestroy() {
    this.destroyCharts()
  }
}
</script>

<style scoped>
.chartDiv {
  bottom: 0;
  width: 100%;
  height: 300px;
}
.fixed-subtitle {
  min-height: 60px;
  max-height: 60px;
}
</style>