<template>
  <div>
    <!-- FOGGZ SELECTION -->
    <v-select
      v-model="selectedFoggz"
      label="Foggz-Paket"
      :items="availableFoggzTypes"
      item-value="id"
      item-text="text"
      @change="addFoggzSuccess = false; addFoggzError = false"
    />
    <!-- BUTTON -->
    <v-row no-gutters>
      <v-col>
        <v-btn
          small
          color="bgInfo"
          :disabled="disableChargeAction || user.userData.id === 0"
          @click="addFoggz"
        >
          <v-icon left>
            mdi-cloud-check-outline
          </v-icon>
          Aufladen
        </v-btn>
      </v-col>
    </v-row>
    <!-- ERROR -->
    <v-row no-gutters v-show="support.foggzSuccess !== null">
      <v-col>
        <v-alert
          v-model="addFoggzSuccess"
          border="left"
          color="floppzPrimary"
          dismissible
          class="mt-5"
        >
          <template #prepend>
            <v-avatar
              size="64"
              class="mr-2"
            >
              <img
                alt="Floppz"
                src="/paypal_thx.png"
              >
            </v-avatar>
          </template>
          <span>
              <strong>
                Foggz erfolgreich hinzugefügt
              </strong>
            </span>
        </v-alert>
        <v-alert
          v-model="addFoggzError"
          border="left"
          color="floppzError"
          dismissible
          class="mt-5"
        >
          <template #prepend>
            <v-avatar
              size="64"
              class="mr-2"
            >
              <img
                alt="Floppz"
                src="/paypal_error.png"
              >
            </v-avatar>
          </template>
          <span>
              <strong>
                {{ support.foggzSuccess }}
                <br>
                Bitte wende dich an die pleX oder CyBex.
              </strong>
            </span>
        </v-alert>
      </v-col>
    </v-row>
    <!-- EXAMPLE -->
    <v-row>
      <v-col>
        <h3>
          ACHTUNG Foggz sind nicht 1:1 Sekunden Nebel!
          <br>
          <small>Somit setzten sich die Pakete wie folgt zusammen.</small>
        </h3>
        <v-simple-table
          dense
          class="mt-4"
        >
          <template #default>
            <thead>
              <tr>
                <th class="text-center table-content">
                  Paket
                </th>
                <th class="text-center table-content">
                  Foggz
                </th>
                <th class="text-center table-empty">
                </th>
                <th class="text-center table-content">
                  NS
                </th>
                <th class="text-center table-empty">
                </th>
                <th class="text-center table-content">
                  NK
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(value, idx) in foggzData[currentLvl]"
                :key="idx"
              >
                <td class="text-center">{{ idx + 1 }}</td>
                <td class="text-center">{{ value }}</td>
                <td class="text-center">=></td>
                <td class="text-center">{{ Math.floor(value / 500) }}</td>
                <td class="text-center">/</td>
                <td class="text-center">{{ Math.floor(value / 1500) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br>
        NS = Nebel Sekunden
        <br>
        NK = Nackenklatschen
        <p class="mt-4">
        Die Anzahl der Foggz ist mit deinem Support Level verbunden.<br>
        Je höher dein Level umso mehr Foggz bekommst du für dein Geld.<br>
        <span v-html="examplePackage"></span>
        </p>
        <p>
          Beispiel-Rechner (aktuelle Supportstufe):
          <v-text-field
            v-model="foggzCalc"
            clearable
            dense
            hide-spin-buttons
            type="number"
            label="Foggz"
            class="mt-4"
          />
          <v-text-field
            dense
            disabled
            label="Nebelsekunden"
            :value="foggzCalc ? Math.floor(foggzCalc / 500) : 0"
          />
          <v-text-field
            dense
            disabled
            label="Nackenklatschen"
            :value="foggzCalc ? Math.floor(foggzCalc / 1500) : 0"
          />
        </p>
      </v-col>
    </v-row>
    <confirmation-dialog
      ref="confirm_dialog"
    />
  </div>
</template>

<script>
import {userStore} from '@/store/user'
import {useSupportStore} from '@/store/support'
import ConfirmationDialog from '@/components/globalComponents/ConfirmationDialog.vue'
import {baseConfig, socket} from "@/services/socket";
import {useSocketStore} from "@/store/socketStore";
export default {
  name: "FoggzRecharge",
  components: {ConfirmationDialog},
  setup() {
    return {
      user: userStore(),
      support: useSupportStore(),
      socketStore: useSocketStore()
    }
  },
  data() {
    return {
      // Foggz
      addFoggzSuccess: false,
      addFoggzError: false,
      selectedFoggz: 0,  // default selection
      foggzTypes: [
        {
          id: 0,
          text: 'Paket 1 (15€)',
          value: 15,
          disabled: false
        },
        {
          id: 1,
          text: 'Paket 2 (25€)',
          value: 25,
          disabled: false
        },
        {
          id: 2,
          text: 'Paket 3 (50€)',
          value: 50,
          disabled: false
        },
        {
          id: 3,
          text: 'Paket 4 (100€)',
          value: 100,
          disabled: false
        },
      ],
      disableChargeAction: false,
      fileName: 'foggz.json',
      foggzData: [],
      foggzCalc: null,
    }
  },
  computed: {
    currentLvl() {
      return this.support.supportInfo.supporter || 0
    },
    deposit() {
      return this.support.supportInfo.deposit
    },
    availableFoggzTypes() {
      const aft = [...this.foggzTypes]
      aft.forEach(foggzObj => {
        if (foggzObj.value > this.deposit) {
          foggzObj.disabled = true
        }
      })
      return aft
    },
    examplePackage() {
      if (!this.foggzData.length) {
        return ''
      }
      let example = '<br><u>Hier mal ein kleines Beispiel:</u><br>'
      example += `Paket 1 ohne Support: <strong>${this.foggzData[0][0]}</strong><br>`
      example += `Paket 1 mit Support 4: <strong>${this.foggzData[4][0]}</strong>`
      return example
    },
    foggzSuccess() {
      return this.support.foggzSuccess
    }
  },
  methods: {
    async addFoggz() {
      this.disableChargeAction = true
      const foggzPackageCost = this.foggzTypes.find(foggzPack => foggzPack.id === this.selectedFoggz)
      const foggzPackageSize = this.foggzData[this.currentLvl][this.selectedFoggz]
      const buttonOptions = {
        dialogTitle: `Möchtest du für ${foggzPackageCost.value}€ deine Foggz aufladen?`,
        dialogText: `Hierdurch wird dein Konto direkt mit <strong>${foggzPackageCost.value}€</strong> belastet.<br>` +
          `Du erhälst dadurch <strong>${foggzPackageSize}</strong> Foggz<br>` +
          `Möchtest du jetzt aufladen?`
      }
      const confirmed = await this.$refs.confirm_dialog.showDialog(buttonOptions)
      if (confirmed) {
        const foggzPayload = {
          uid: this.user.userData.id,
          package: this.selectedFoggz
        }
        this.support.setFoggz(foggzPayload)
      }
      else {
        this.disableChargeAction = false
      }
    },
    requestFoggzData() {
      this.socketStore.requestJSON(this.fileName)
    }
  },
  async created() {
    socket.on(`<${baseConfig.loginname}<cs-media.www<`, (event, data, fileName) => {
      if (event === 'media.getprivatjson' && fileName && fileName === this.fileName) {
        this.foggzData = data ? JSON.parse(data) : []
      }
    })
    await this.$nextTick()
    await this.requestFoggzData()
  },
  watch: {
    foggzSuccess(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.disableChargeAction = false
        this.addFoggzError = false
        this.addFoggzSuccess = false
      }
      if (newVal === true) {
        this.addFoggzError = false
        this.addFoggzSuccess = true
        this.support.getSupportInfo(this.user.userData.id)
      }
      else {
        this.addFoggzError = true
        this.addFoggzSuccess = false
      }
    }
  }
}
</script>

<style scoped>
.table-content {
  width: 20% !important;
}
.table-empty {
  width: 5% !important;
}
</style>