import { baseConfig, socket } from '@/services/socket'
// stores
import { useSupportStore } from '@/store/support'
import { useNotificationStore } from '@/store/notifaction'
// import {useSocketStore} from "@/store/socketStore";

// const socketAnyRecv = (event, data) => {
const socketAnyRecv = (event) => {
  switch (event) {
    case 'service.forceReload':
      window.location.reload()
      break
  }
}
const socketAnyRepl = (event, data, ...args) => {
  const SUPPORT_STORE = useSupportStore()
  const NOTIFICATION_STORE = useNotificationStore()
  let firstArg = ''
  let message = ''
  switch (event) {
    case 'service.getuid':
      SUPPORT_STORE.setUserSupport(data)
      break
    case 'media.getprivattext':
      firstArg = args.shift()
      if (firstArg.startsWith('force')) {
        SUPPORT_STORE.setForceChangeText(data)
      }
      else {
        SUPPORT_STORE.setChangeText(data)
      }
      break
    case 'service.adddonation!ack':
      message = data.message || 'Trinkgeld erfolgreich eingegangen'
      SUPPORT_STORE.setDonationSuccess(message)
      break
    case 'service.adddonation!nack':
      message = data.message || 'Fehler bei Trinkgeld Transaktion'
      SUPPORT_STORE.setDonationFailed(message)
      break
    case 'service.addsportdonation!ack':
      message = data.message || 'Sportgeld erfolgreich eingegangen'
      SUPPORT_STORE.setDonationSuccess(message)
      SUPPORT_STORE.ignoreBroadcast = false
      break
    case 'service.addsportdonation!nack':
      message = data.message || 'Fehler bei Sportgeld Transaktion'
      SUPPORT_STORE.setDonationFailed(message)
      break
    case 'service.addsupport!ack':
      message = data.message || 'Zahlung erfolgreich eingegangen'
      SUPPORT_STORE.setSupportSuccess(message)
      break
    case 'service.getsportbutton!ack':
      SUPPORT_STORE.setSportDonationButton(data)
      break
    case 'service.addfoggz!ack':
      SUPPORT_STORE.setFoggzSuccess()
      break
    case 'service.addfoggz!nack':
      SUPPORT_STORE.setFoggzFailed(data)
      break
    case 'redir.list':
      SUPPORT_STORE.setRedirList(data)
      break
    case 'redir.add!ack':
      SUPPORT_STORE.getRedirList()
      break
    case 'redir.add!nack':
      NOTIFICATION_STORE.setError({
        'title': 'redirect ADD error',
        'text': data
      })
      break
    case 'redir.del!ack':
      SUPPORT_STORE.getRedirList()
      break
    case 'redir.del!nack':
      NOTIFICATION_STORE.setError({
        'title': 'redirect DEL error',
        'text': data
      })
      break
    case 'redir.rename!ack':
      SUPPORT_STORE.getRedirList()
      break
    case 'redir.rename!nack':
      NOTIFICATION_STORE.setError({
        'title': 'redirect RENAME error',
        'text': data
      })
      break
    case 'redir.change!ack':
      SUPPORT_STORE.getRedirList()
      break
    case 'redir.change!nack':
      NOTIFICATION_STORE.setError({
        'title': 'redirect CHANGE error',
        'text': data
      })
      break
    default:
      break
  }
}
const socketAnyBcst = (event, data) => {
  const SUPPORT_STORE = useSupportStore()
  switch (event) {
    case 'service.getsportbutton!ack':
      SUPPORT_STORE.setSportDonationButton(data)
      break
    case 'service.forceBcstReload':
      window.location.reload()
      break
  }
}

const sendToSocket = (rerun = 1, target, eventName, ...args) => {
  if (rerun > 10) {
    return
  }
  if (baseConfig.authed) {
    const socketTo = `>${baseConfig.loginname}>${target}>`
    socket.emit(socketTo, eventName, ...args)
  }
  else {
    setTimeout(sendToSocket, 500 * rerun, rerun + 1, target, eventName, ...args)
  }
}
const sendStatusUpdate = (toPath, userName) => {
  const socketMsg = {
    login: userName,
    page: toPath,
    ua: navigator.userAgent
  }
  sendToSocket(1, 'ws-public', 'page.userinfo', JSON.stringify(socketMsg))
}
const socketEmit = (target, eventName, ...args) => {
  if (baseConfig.authed) {
    const socketTo = `>${baseConfig.loginname}>${target}>`
    socket.emit(socketTo, eventName, args)
  }
}

export {
  socketAnyRecv,
  socketAnyRepl,
  socketAnyBcst,
  sendStatusUpdate,
  sendToSocket,
  socketEmit
}