import Vue from 'vue'
import App from './App.vue'
import vuetify from "@/vuetify"
import router from "@/router/router"
import pinia from '@/store/store'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  pinia,
  render: h => h(App),
}).$mount('#app')