<template>
  <div>
    <h1>Marbles GrandPrix Ergebnisse</h1>
    <v-data-table
      dense
      :loading="loading"
      :headers="tableHeaders"
      :items="tableContent"
      :footer-props="tableFooter"
      :search="tableSearch"
      class="mt-5 shaped"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="tableSearch"
              label="Suche"
              class="mx-2"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              :show-current-page="true"
              :show-first-last-page="true"
              :items-per-page-options="tableFooter.itemsPerPageOptions"
              :items-per-page-text="tableFooter.itemsPerPageText"
            ></v-data-footer>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="(user, idx) in items" :key="idx">
          <td v-for="header in tableHeaders" :key="header.text">
            <!-- CLASS BASED COLOR -->
            <span v-if="header.text === 'Name'" :class="user.class">
              {{ getValue(user, header) }}
            </span>
            <!-- POSITION & POINTS -->
            <span v-else-if="header.text.startsWith('Rennen')" v-html="getRaceAndPoints(user, header)">
            </span>
            <!-- DEFAULT VALUE -->
            <span v-else>
              {{ getValue(user, header) }}
            </span>
          </td>
        </tr>
        </tbody>
    
      </template>
  
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

const webServer = process.env.VUE_APP_WEB_SERVER

export default {
  name: "MarblesGrandPrix",
  data() {
    return {
      loading: false,
      tableSearch: '',
      tableHeaders: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Gesamtpunkte',
          value: 'points'
        },
        {
          text: 'Rennen 1',
          value: 'race1place'
        },
        {
          text: 'Rennen 2',
          value: 'race2place'
        },
        {
          text: 'Rennen 3',
          value: 'race3place'
        },
        {
          text: 'Rennen 4',
          value: 'race4place'
        },
        {
          text: 'Rennen 5',
          value: 'race5place'
        },
      ],
      tableContent: [],
      tableFooter: {
        itemsPerPageOptions: [100, 200, -1],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: 'Einträge pro Seite'
      },
    }
  },
  computed: {
    getRaceAndPoints() {
      return (item, header) => {
        const placeText = header.value
        const pointsText = placeText.replace('place', 'points')
        return `<strong>${item[placeText]}</strong> (${item[pointsText]})`
      }
    },
    getValue() {
      return (item, header) => {
        return item[header.value]
      }
    }
  },
  methods: {
    async getGrandPrixResults() {
      const { data: gpResults } = await axios.get(`${webServer}/json/marbles.json`)
      if (gpResults.length) {
        this.tableContent = gpResults
        this.tableContent.forEach((marbler, index) => {
          marbler.index = index + 1
        })
      }
      this.loading = false
    }
  },
  mounted() {
    this.$emit('updateMounted')
  },
  async created() {
    this.loading = true
    await this.getGrandPrixResults()
  }
}
</script>

<style scoped>
.broadcaster {
  color: #df3f3f;
  font-weight: bold;
}
.mod {
  color: #3fdfdf;
}
.vip {
  color: #ff00ff;
}
.sub {
  color: #7f7fdf;
}
.viewer {
  color: #dfdfdf;
}
.theme--dark.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #303030;
}


.odd .broadcaster { color:#bf3f3f; } .even .broadcaster { color:#df3f3f; }
.odd .mod { color:#3fbfbf; } .even .mod { color:#3fdfdf; }
.odd .vip { color:#bf3fbf; } .even .vip { color:#df3fdf; }
.odd .sub { color:#5f5fbf; } .even .sub { color:#7f7fdf; }
.odd .viewer { color:#dfdfdf;} .even .viewer { color:#bfbfbf; }
</style>