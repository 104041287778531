<script setup>
import { defineEmits, nextTick, onBeforeUnmount, ref } from 'vue'
import { useAdminStore } from '@/store/admin'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
// STORES
const adminStore = useAdminStore()
// EMITS
const emit = defineEmits(['updateMounted'])
// DATA
const shownCharts = ref([])
const chartTypes = ref([])
const chartShow = ref({})
const chartRefs = ref([])
const charts = ref([])
// METHODS
function prepareChartTypes() {
  chartTypes.value = []
  if (adminStore.potData.length > 0) {
    const refObj = {}
    Object.keys(adminStore.potData[0]).forEach(key => {
      if (key !== 'date') {
        chartTypes.value.push(key)
        refObj[key] = true
      }
    })
    chartShow.value = refObj
    let count = 0
    chartTypes.value.forEach(() => {
      shownCharts.value.push(count)
      count++
    })
  }
}
function createCharts() {
  if (chartTypes.value.length > 0) {
    chartTypes.value.forEach((chartName) => {
      const chartDiv = chartRefs.value[chartName]
      // https://www.amcharts.com/docs/v5/concepts/colors-gradients-and-patterns/
      const chartRoot = am5.Root.new(chartDiv)
      chartRoot.setThemes([
        am5themes_Animated.new(chartRoot)
      ])
      chartRoot.interfaceColors.set('text', am5.color('#ffffff'))

      const chart = chartRoot.container.children.push(am5xy.XYChart.new(chartRoot, {
        cursorTooltipEnabled: true
      }))

      const cursor = chart.set('cursor', am5xy.XYCursor.new(chartRoot, {
        behavior: 'none'
      }))
      cursor.lineY.set('visible', false)

      const xAxis = chart.xAxes.push(am5xy.DateAxis.new(chartRoot, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: 'minute',
          count: 5
        },
        renderer: am5xy.AxisRendererX.new(chartRoot, {}),
        tooltip: am5.Tooltip.new(chartRoot, {})
      }))
      const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(chartRoot, {
        renderer: am5xy.AxisRendererY.new(chartRoot, { opposite: true }),
        tooltip: am5.Tooltip.new(chartRoot, {})
      }))

      const seriesChart = chart.series.push(am5xy.LineSeries.new(chartRoot, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: chartName,
        valueXField: 'date',
        cursorTooltipEnabled: false,
        tooltip: am5.Tooltip.new(chartRoot, {
          labelText: '{valueY}'
        })
      }))

      seriesChart.data.processor = am5.DataProcessor.new(chartRoot, {
        numericFields: [chartName]
      })

      seriesChart.strokes.template.setAll({
        strokeWidth: 3
      })

      chart.set('scrollbarX', am5.Scrollbar.new(chartRoot, {
        orientation: 'horizontal'
      }))

      seriesChart.data.setAll(adminStore.potData)

      seriesChart.appear(1000, 200)
      chart.appear(1000, 200)

      charts.value.push(chartRoot)
    })
  }
}
function disposeCharts() {
  charts.value.forEach((chart) => {
    chart.dispose()
  })
}
//CREATED
async function created() {
  emit('updateMounted')
  await adminStore.fetchPotData()
  await prepareChartTypes()
  await nextTick()
  await createCharts()
  setTimeout(() => {
    disposeCharts()
    created()
  }, 1000 * 60 * 15)
}
created()
// UNMOUNT
onBeforeUnmount(() => {
  disposeCharts()
})

function toggleChart(name) {
  chartShow.value[name] = !chartShow.value[name]
}
</script>

<template>
  <div>
    <v-row>
      <v-col>
        <v-btn-toggle
          v-model="shownCharts"
          dark
          dense
          multiple
        >
          <v-btn
            v-for="(chartName, idx) in chartTypes"
            :key="chartName"
            color="primary"
            @click="toggleChart(chartName)"
          >
            <v-icon v-show="shownCharts.includes(idx)">mdi-check</v-icon>
            {{ chartName.toUpperCase() }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row v-for="(chartName, idx) in chartTypes" v-show="chartShow[chartName]" :key="idx">
      <v-col>
        <v-card color="bgSecondary" shaped style="height: 100%">
          <v-card-title><h2>{{ chartName.toUpperCase() }}</h2></v-card-title>
          <v-card-text>
            <div :ref="(el) => chartRefs[chartName] = el" class="chartDiv"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.chartDiv {
  bottom: 0;
  width: 100%;
  height: 300px;
}
</style>