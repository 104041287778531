import { defineStore } from 'pinia'
import axios from 'axios'

const webServer = process.env.VUE_APP_WS_SERVER

export const foggzStore = defineStore('foggzStore', {
  state: () => {
    return {
      userFoggz: {
        totalfoggz: '0',
        totaldiff: '0'
      },
      chartFoggz: []
    }
  },
  actions: {
    async fetchUserFoggz() {
      const { data: jsonData } = await axios.get(`${webServer}/json/userfoggz.json`)
      if (jsonData) {
        this.userFoggz = jsonData
        this.userFoggz.user.forEach((userFoggz, index) => {
          userFoggz.index = index + 1
        })
      }
    },
    async fetchChartFoggz() {
      const { data: jsonData } = await axios.get(`${webServer}/json/foggz.json`)
      if (jsonData) {
        this.chartFoggz = jsonData
      }
    },
    async fetchFoogzData() {
      await this.fetchUserFoggz()
      await this.fetchChartFoggz()
    }
  },
  getters: {
    getUserFoggz: (state) => {
      return state.userFoggz.user
    }
  }
})