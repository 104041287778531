<template>
  <span>
    <!-- LOGGED IN -->
    <v-card color="bgSecondary" shaped v-if="user.isLoggedIn">
      <v-card-title>
        <v-avatar size="56">
          <img
            v-if="user.userData.profile_image_url"
            :src="user.userData.profile_image_url"
            :alt="user.userData.display_name + '_profil'"
          >
        </v-avatar>&nbsp;
        <h2>{{ user.userData.display_name }}</h2>
      </v-card-title>
      <v-card-subtitle>
        Stream Daten (Droppz, Aktien, usw.)
      </v-card-subtitle>
      <v-divider />
      <v-card-text>
        <div :style="'max-width:' + doubleMaxWidth">
          <v-row>
            <v-col>
              <user-status :max-width="maxWidth" />
            </v-col>
            <v-col>
              <foundation-status :max-width="maxWidth" />
            </v-col>
          </v-row>
        </div>
        <v-divider class="mt-5 mb-5" />
        <foundation-main :max-width="maxWidth" />
        <v-divider class="mt-5 mb-5" />
        <foundation-pay-pal :max-width="maxWidth" />
      </v-card-text>
    </v-card>
    <!-- NOT LOGGED IN -->
    <v-card color="bgSecondary" v-else>
      <v-card-title>
        <v-avatar size="56" left>
          <v-icon x-large>mdi-account-question-outline</v-icon>
        </v-avatar>
        <h2>Nicht eingeloggt.</h2>
      </v-card-title>
      <v-card-subtitle>
        Bitte erst einloggen...
      </v-card-subtitle>
      <v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-btn
          class="twitchLogin"
          @click="user.loginTwitch()"
        >
          <v-icon
            color="twitch"
            left
          >
            mdi-twitch
          </v-icon>
          Login with twitch
        </v-btn>
      </v-card-text>
    </v-card>
    <br>
    <v-btn v-if="userIsMod" @click="openSongCheck()">
      <v-icon left>mdi-music</v-icon>
      Songcheck
    </v-btn>
  </span>
</template>

<script>
import {userStore} from '@/store/user'
import {droppzStore} from '@/store/droppz'
import {globStore} from '@/store/glob'
import {aktienStore} from '@/store/aktien'
import FoundationMain from '@/components/foundation/FoundationMain.vue'
import UserStatus from "@/components/user/UserStatus.vue";
import FoundationStatus from "@/components/foundation/FoundationStatus.vue";
import FoundationPayPal from "@/components/foundation/FoundationPayPal.vue";

export default {
  name: "UserProfile",
    components: {FoundationPayPal, FoundationStatus, UserStatus, FoundationMain},
  setup() {
    return {
      maxWidth: '600px',
      user: userStore(),
      droppz: droppzStore(),
      glob: globStore(),
      aktien: aktienStore()
    }
  },
  data() {
    return {
      aktienWert: 0
    }
  },
  computed: {
    doubleMaxWidth() {
      let value = 100
      let type = '%'
      const regMatch = this.maxWidth.match(/(?<value>\d+)(?<type>\D+)/)
      if (regMatch && regMatch.groups) {
        value = regMatch.groups.value * 2
        type = regMatch.groups.type
      }
      return `${value}${type}`
    },
    userIsMod() {
      return this.glob.getUserIsMod(this.user.userData.id)
    },
    getSubInfo() {
      if (this.user.isSub) {
        let img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub0.png')}">`
        switch (this.user.isSub) {
          case 1:
            img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub1.png')}">`
            break
          case 2:
            img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub2.png')}">`
            break
          case 3:
            img = `<img class="subemote" alt="subemote" src="${require('@/static/emotes/sub3.png')}">`
            break
        }
        return `Stufe ${this.user.isSub} ${img}`
      }
      return 'Nein'
    },
    userDroppz() {
      if (this.droppz.userDroppz.user) {
        const userDroppz = this.droppz.getUserDroppz
        const usersDroppz = userDroppz.find(droppz => droppz.user.toLowerCase() === this.user.userData.display_name.toLowerCase())
        if (usersDroppz?.droppz) {
          return usersDroppz.droppz
        }
        return 0
      }
      return 'Droppz konnten nicht geladen werden'
    },
    userAktien() {
      this.resetAktienWert()
      if (this.glob.aktien && this.user.userData.id > 0) {
        const aktienArr = []
        const userAktien = this.glob.getUserAktien
        const latestAktien = this.aktien.aktienAktuell
        if (userAktien && latestAktien) {
          const usersAktie = userAktien[this.user.userData.id]
          if (usersAktie) {
            usersAktie.Aktie.forEach((aktien, index) => {
              if (aktien > 0) {
                const aktienAbk = this.aktien.aktienDesc[index].Abk
                const aktienValue = latestAktien['aktie' + (index + 1)] * aktien
                this.aktienWert += aktienValue
                aktienArr.push(`${aktienAbk}: ${aktien}`)
              }
            })
            if (aktienArr.length) {
              return aktienArr.join(' | ')
            }
          }
        }
      }
      return 'Keine Aktien'
    },
    userLottoLos() {
      let lottoLos = 'Kein aktuelles Los'
      if (this.glob.lotto && this.user.userData.id > 0) {
        if (this.glob.lotto.NaechsteZiehung && this.glob.lotto.NaechsteZiehung.Lose && this.glob.lotto.NaechsteZiehung.Lose[this.user.userData.id]) {
          lottoLos = this.glob.lotto.NaechsteZiehung.Lose[this.user.userData.id]
        }
      }
      return lottoLos
    },
    userDauerlos() {
      let lottoLos = 'Kein Dauerlos'
      if (this.glob.lotto && this.user.userData.id > 0) {
        if (this.glob.lotto.Dauerlos && this.glob.lotto.Dauerlos.Lose && this.glob.lotto.Dauerlos.Lose[this.user.userData.id]) {
          lottoLos = `${this.glob.lotto.Dauerlos.Lose[this.user.userData.id]}`
        }
      }
      return lottoLos
    }
  },
  methods: {
    openSongCheck() {
      const userId = this.user.userData.id
      window.open(`http://cybex.has.nolife.org/floppz/songcheck.cgi?UID=${userId}`,'_blank')
    },
    resetAktienWert() {
      this.aktienWert = 0
    }
  },
  mounted() {
    this.$emit('updateMounted')
  },
  async created() {
    await this.glob.fetchGlobData()
    if (!this.aktien.aktienDesc.length) {
      await this.aktien.fetchAktienDesc()
      await this.aktien.fetchAktienValues()
    }
    if (!this.droppz.userDroppz.user) {
      await this.droppz.fetchUserDroppz()
    }
  }
}
// TODO:
// Mod userprofile check
// on load get => socket.emit('>...>cs-service', 'service.getuid', 12345)
// watchtime
// lastseen
// etc.
// using query paramter
// https://floppz.de/#/profile?user=smicer777
</script>

<style scoped>
:deep() .subemote {
  width: 12px;
}
.twitchLogin:hover {
  background-color: #772ce8;
  cursor: pointer;
}
</style>