<template>
  <v-card :max-width="maxWidth">
    <v-expansion-panels
      v-model="expanded"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          color="bgInfo"
        >
          Foundation Status
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list dense>
            <!-- SUPPORT INFO -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Support
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="ml-5">
                  Stufe:
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-subtitle>
                  {{ currentLvl }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="ml-5">
                  Zyklen
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-subtitle>
                  {{ supportCycle }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="ml-5">
                  Support Punkte
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-subtitle>
                  {{ supportPoints }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item v-show="nextCycle !== currentLvl">
              <v-list-item-content>
                <v-list-item-title>
                  Nächste Stufe:
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-title>
                  {{ nextCycle }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-show="nextCycle !== currentLvl" />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Restlaufzeit (Streamtage):
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-title>
                  {{ daysRemain }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Guthaben:
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-title>
                  {{ deposit }} €
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Foggz:
                </v-list-item-title>
                <v-list-item-subtitle>
                  Entspricht (entweder - oder):
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-title>
                  {{ foggzValue }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="ml-5">
                  Nebel Sekunden:
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-subtitle>
                  {{ nebelSekunden }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="ml-5">
                  Nackenklatschen:
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-subtitle>
                  {{ nackenKlatschen }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-show="taubenklatschen">
              <v-list-item-content>
                <v-list-item-subtitle class="ml-5">
                  Taubenklatschen:
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-subtitle>
                  {{ taubenklatschen }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import {userStore} from '@/store/user'
import {useSupportStore} from '@/store/support'
export default {
  name: "FoundationStatus",
  setup() {
    return {
      user: userStore(),
      support: useSupportStore()
    }
  },
  props: {
    maxWidth: {
      type: String,
      default: "100%"
    }
  },
  data() {
    return {
      expanded: 0
    }
  },
  computed: {
    currentLvl() {
      return this.support.supportInfo.supporter
    },
    daysRemain() {
      const daysLeft = this.support.supportInfo.supportdaysremain - 1
      if (daysLeft === 0) {
        return 'Letzter Tag'
      }
      else if (daysLeft < 0) {
        return 'Support ausgelaufen'
      }
      return daysLeft
    },
    deposit() {
      return this.support.supportInfo.deposit
    },
    supportCycle() {
      return this.support.supportInfo.supportcycle || 0
    },
    supportPoints() {
      return this.support.supportInfo.suppoints || 0
    },
    nebelSekunden() {
      return this.support.supportInfo.nebel || 0
    },
    nackenKlatschen() {
      return this.support.supportInfo.nackenklatschen || 0
    },
    taubenklatschen() {
      return this.support.supportInfo.megastrob || 0
    },
    nextCycle() {
      return this.support.supportInfo.supportnextcycle || this.support.supportInfo.supporter
    },
    foggzValue() {
      return this.support.supportInfo.foggz || 0
    }
  }
}
</script>

<style scoped>
</style>