import { defineStore } from 'pinia'
import axios from "axios";

const webServer = process.env.VUE_APP_WEB_SERVER

export const aktienStore = defineStore('aktienStore', {
  state: () => {
    return {
      aktienDesc: [],
      aktienState: [],
      aktienValues: []
    }
  },
  actions: {
    async fetchAktienDesc() {
      const { data: jsonData } = await axios.get(`${webServer}/json/aktien_desc.json`)
      if (jsonData) {
        this.aktienDesc = jsonData
      }
    },
    async fetchAktienState() {
      const { data: jsonData } = await axios.get(`${webServer}/json/aktien.json`)
      if (jsonData) {
        this.aktienState = jsonData
      }
    },
    async fetchAktienValues() {
      const { data: jsonData } = await axios.get(`${webServer}/json/aktie.json`)
      if (jsonData) {
        this.aktienValues = jsonData
      }
    },
    async fetchAktienData() {
      await this.fetchAktienDesc()
      await this.fetchAktienState()
      await this.fetchAktienValues()
    }
  },
  getters: {
    hasAktien: (state) => {
      return !!state.aktienDesc.length;

    },
    aktienAktuell: (state) => {
      const anzahl = state.aktienValues.length
      return state.aktienValues[anzahl - 1]
    },
    aktienVortag: (state) => {
      const anzahl = state.aktienValues.length
      return state.aktienValues[anzahl - 2]
    }
  }
})