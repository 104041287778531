<template>
  <div>
    <v-dialog
      width="500"
      v-model="addGameDialog"
      @click:outside="addCancel"
      @keydown.esc="addCancel"
    >
      <v-card>
        <v-card-title>
          Neues Spiel vorschlagen
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row v-if="isMod">
            <v-col>
              <v-text-field
                dense
                clearable
                autofocus
                label="Eintragen für User:"
                v-model="addGameObj.user"
                placeholder="Wird kein User angegeben wird der aktuell verwendet"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                dense
                clearable
                autofocus
                label="Spieltitel"
                v-model="addGameObj.name"
                :rules="[gameAddRules.existRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col>
              <v-text-field
                dense
                clearable
                label="Shop Link"
                v-model="addGameObj.link"
                :rules="[gameAddRules.linkRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col>
              <v-select
                dense
                clearable
                label="Single/Multiplayer"
                :items="gamePlayerTypes"
                item-text="typeText"
                item-value="typeId"
                v-model="addGameObj.player"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="addGame"
            :disabled="!addGameObj.name || !addGameObj.link || addGameHasError"
          >
            Vorschlagen
          </v-btn>
          <v-btn
            color="warning"
            text
            @click="addCancel"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AddGame",
  props: [
    'gameList',
    'isMod'
  ],
  data() {
    return {
      gameAddRules: {
        existRule: value => !this.gameExists(value) || 'Wurde schon vorgeschlagen',
        linkRule: value => this.isValidLink(value) || 'Bitte gültige URL angeben',
      },
      addGameDialog: false,
      addGameObj: {
        name: null,
        link: null,
        genres: [],
        player: null,
        user: null
      },
      gamePlayerTypes: [
        {typeId: 1, typeText: 'Singleplayer'},
        {typeId: 2, typeText: 'Multiplayer'}
      ]
    }
  },
  computed: {
    addGameHasError() {
      const gameError = this.gameExists(this.addGameObj.name)
      const linkError = !this.isValidLink(this.addGameObj.link)
      return gameError || linkError
    }
  },
  methods: {
    clearGameObj() {
      this.addGameObj = {
        name: null,
        link: null,
        genres: [],
        player: null,
        user: null
      }
    },
    addCancel() {
      this.addGameDialog = false
      this.clearGameObj()
    },
    addGame() {
      this.addGameDialog = false
      this.$emit('add:game', this.addGameObj)
      this.clearGameObj()
    },
    gameExists(value) {
      if (value) {
        const gameExists = this.gameList.find(game => game.name.toLowerCase() === value.trim().toLowerCase())
        if (gameExists) {
          return true
        }
      }
      return false
    },
    isValidLink(value) {
      try {
        new URL(value)
        return true
      }
      catch (err) {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>