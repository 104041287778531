<template>
  <div v-show="user.isMod">
    <v-row>
      <v-col>
        <v-btn-toggle
          v-model="selectedButton"
          shaped
        >
          <v-btn
            color="primary"
            @click="loadSongCheck"
          >
            Songcheck
          </v-btn>
          <v-btn
            color="primary"
            @click="loadWatchTime"
          >
            Watchtime
          </v-btn>
          <v-btn
            color="primary"
            @click="loadShortLink"
          >
            Shortlink
          </v-btn>
          <v-btn
            color="primary"
            @click="loadPots"
          >
            Pots
          </v-btn>
          <v-btn
            color="primary"
            @click="loadBotStats"
          >
            BotPage
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="selection === 'watchtime'"
        cols="12"
      >
        <watch-time @updateMounted="updateMounted" />
      </v-col>
      <v-col
        v-if="selection === 'shortlink'"
        cols="12"
      >
        <short-link @updateMounted="updateMounted" />
      </v-col>
      <v-col
        v-if="selection === 'pots'"
        cols="12"
      >
        <pots-info @updateMounted="updateMounted" />
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <iframe :src="iframeSrc">
          Dein Browser mag keine iFrames :(
        </iframe>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { userStore } from '@/store/user'
import { globStore } from '@/store/glob'
import WatchTime from '@/components/admin/WatchTime.vue'
import ShortLink from '@/components/admin/ShortLink.vue'
import PotsInfo from '@/components/admin/PotsInfo.vue'

export default {
  name: 'ModMenu',
  components: { PotsInfo, ShortLink, WatchTime },
  setup() {
    return {
      user: userStore(),
      glob: globStore()
    }
  },
  data() {
    return {
      selection: null,
      selectedButton: null,
      iframeSrc: '',
      selectionMap: {
        'songcheck': this.loadSongCheck,
        'botstats': this.loadBotStats
      }
    }
  },
  methods: {
    loadSongCheck() {
      this.selection = 'songcheck'
      const userId = this.user.userData.id
      this.iframeSrc = `${process.env.VUE_APP_WEB_SERVER}/rewritecybex/floppz/songcheck.cgi?UID=${userId}`
    },
    loadWatchTime() {
      this.selection = 'watchtime'
      this.iframeSrc = ''
    },
    loadShortLink() {
      this.selection = 'shortlink'
      this.iframeSrc = ''
    },
    loadPots() {
      this.selection = 'pots'
      this.iframeSrc = ''
    },
    loadBotStats() {
      this.selection = 'botstats'
      this.iframeSrc = `${process.env.VUE_APP_WEB_SERVER}/1982/#/raid-gp`
    },
    updateMounted() {
      this.$emit('updateMounted')
    }
  },
  mounted() {
    this.updateMounted()
  },
  async created() {
    if (!this.glob.admin.length) {
      await this.glob.fetchGlobData()
    }
    const pageParam = this.$route.query.page
    if (pageParam) {
      this.selection = pageParam
      if (this.selectionMap[pageParam]) {
        this.selectionMap[pageParam]()
      }
    }
  }
}
</script>

<style scoped>
iframe {
  position: relative;
  width: 100%;
  height: 100dvh;
  border: none;
}
</style>