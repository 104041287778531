<script setup>
import { computed, ref } from 'vue'
import { useSupportStore } from '@/store/support'
// STORES
const supportStore = useSupportStore()
// EMITS
// eslint-disable-next-line no-undef
const emit = defineEmits(['updateMounted'])
// DATA
const selectedPath = ref([])
const selectedPathEditing = ref({})
const selectedName = ref('')
const selectedLink = ref('')
const forceDelete = ref(false)
const createNew = ref(false)
// COMPUTED
const shortLinksArray = computed(() => {
  return recurseRedirs(supportStore.redirList)
})
const forceStatus = computed(() => {
  return forceDelete.value ? 'warning' : 'default'
})
const redirectValue = computed(() => {
  const base_url = 'https://r.floppz.de'
  let path = '/'
  if (selectedPathEditing.value.parent.length) {
    path += selectedPathEditing.value.parent.join('/') + '/'
  }
  path += `${selectedName.value}`
  return base_url + path
})
// METHODS
function recurseRedirs(path, parent = []) {
  const returnArr = []
  if (path) {
    Object.keys(path).forEach((key) => {
      const parentPath = [...parent]
      parentPath.push(key)
      const subPaths = recurseRedirs(path[key].sub, parentPath)
      const newPathObj = {
        name: key,
        link: path[key].link,
        sub: subPaths,
        parent: parent
      }
      returnArr.push(newPathObj)
    })
  }
  return returnArr
}
function unselectValues() {
  createNew.value = false
  forceDelete.value = false
  selectedPath.value = []
  selectedPathEditing.value = {}
  selectedName.value = ''
  selectedLink.value = ''
}
function changeSelected(pathArr) {
  emit('updateMounted')
  if (pathArr.length >= 1) {
    createNew.value = false
    selectedPathEditing.value = pathArr[0]
    selectedName.value = pathArr[0].name
    selectedLink.value = pathArr[0].link
  }
}
function addPath() {
  createNew.value = true
  selectedPathEditing.value = {
    name: '',
    link: '',
    sub: [],
    parent: []
  }
  selectedName.value = ''
  selectedLink.value = ''
}
function addSubPath() {
  createNew.value = true
  const parentPath = [...selectedPath.value[0].parent]
  parentPath.push(selectedPath.value[0].name)
  selectedPathEditing.value = {
    name: '',
    link: '',
    sub: [],
    parent: parentPath
  }
  selectedName.value = ''
  selectedLink.value = ''
}
function deleteShortLink() {
  const deleteObj = {
    name: selectedPathEditing.value.name
  }
  if (forceDelete.value) {
    deleteObj.force = true
  }
  let deleteRedirObj = {}
  let lastParent = null
  selectedPathEditing.value.parent.forEach((parent) => {
    if (!lastParent) {
      deleteRedirObj.name = parent
      lastParent = deleteRedirObj
    }
    else {
      lastParent.sub = {
        name: parent
      }
      lastParent = lastParent.sub
    }
  })
  if (!lastParent) {
    deleteRedirObj = deleteObj
  }
  else {
    lastParent.sub = deleteObj
  }
  supportStore.sendDelRedir(deleteRedirObj)
  unselectValues()
}
function saveShortLink() {
  let saveRedirObj = {}
  let lastParent = null
  selectedPathEditing.value.parent.forEach((parent) => {
    if (!lastParent) {
      saveRedirObj.name = parent
      lastParent = saveRedirObj
    }
    else {
      lastParent.sub = {
        name: parent
      }
      lastParent = lastParent.sub
    }
  })
  if (createNew.value) {
    const newObj = {
      name: selectedName.value,
      link: selectedLink.value
    }
    if (!lastParent) {
      saveRedirObj = newObj
    }
    else {
      lastParent.sub = newObj
    }
    supportStore.sendNewRedir(saveRedirObj)
  }
  else {
    const changeObj = {}
    if (selectedPathEditing.value.name !== selectedName.value) {
      changeObj.name = selectedPathEditing.value.name
      changeObj.new = selectedName.value
      if (!lastParent) {
        saveRedirObj = changeObj
      }
      else {
        lastParent.sub = changeObj
      }
      supportStore.sendRenameRedir(saveRedirObj)
    }
    else if (selectedPathEditing.value.link !== selectedLink.value) {
      changeObj.name = selectedPathEditing.value.name
      changeObj.link = selectedLink.value
      if (!lastParent) {
        saveRedirObj = changeObj
      }
      else {
        lastParent.sub = changeObj
      }
      supportStore.sendChangeRedir(saveRedirObj)
    }
  }
  unselectValues()
}
async function copyToClipboard(event) {
  event.target.style.color = 'lightgreen'
  const copyText = redirectValue.value.toString()
  await navigator.clipboard.writeText(copyText)
  setTimeout(() => {
    event.target.style.color = 'white'
  }, 1000)
}
// CREATED
function created() {
  supportStore.getRedirList()
}
created()
</script>

<template>
  <v-card
    elevation="2"
    outlined
    shaped
    style="min-height: fit-content"
  >
    <v-card-title class="content-bg-info-text font-weight-bold">
      Redirect-Shortlinks
    </v-card-title>
    <v-card-text class="mt-5 justify-center">
      <v-row>
        <v-col cols="5">
          <v-treeview
            :active.sync="selectedPath"
            :items="shortLinksArray"
            activatable
            color="primary"
            item-children="sub"
            item-key="name"
            item-text="name"
            return-object
            @update:active="changeSelected"
          >
            <template #prepend="{ item }">
              <v-icon v-if="item.link">
                mdi-link-variant
              </v-icon>
            </template>
            <template #label="{item}">
              <v-list-item
                style="cursor: pointer"
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.link }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-treeview>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="7">
          <div v-if="!selectedPath.length && !createNew">
            Bitte Link auswählen oder neu<br>
            <v-btn
              color="primary"
              @click="addPath"
            >
              <v-icon>
                mdi-plus
              </v-icon>
              Hinzufügen
            </v-btn>
          </div>
          <div v-else>
            <v-row>
              <v-col>
                <v-btn
                  v-if="!createNew && !selectedPath[0].parent.length"
                  color="primary"
                  @click="addSubPath"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                  Sub-Path hinzufügen
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  @click="unselectValues"
                >
                  <v-icon>mdi-close</v-icon>
                  abbrechen
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="selectedName"
                  label="Name"
                />
                <v-text-field
                  v-model="selectedLink"
                  label="Link"
                >
                  <template #append>
                    <a :href="selectedLink" target="_blank">
                      <v-icon>mdi-link-variant</v-icon>
                    </a>
                  </template>
                </v-text-field>
                <v-text-field
                  :value="redirectValue"
                  label="shorturl"
                  readonly
                >
                  <template #append>
                    <v-tooltip bottom>
                      <template #activator="copyTip">
                        <v-icon
                          v-bind="copyTip.attrs"
                          @click="copyToClipboard"
                          v-on="copyTip.on"
                        >
                          mdi-content-copy
                        </v-icon>
                      </template>
                      <span>In zwischenablage kopieren</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  color="success"
                  small
                  @click="saveShortLink"
                >
                  <v-icon>mdi-content-save-edit-outline</v-icon>
                  speichern
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-tooltip
                  v-if="!createNew && selectedPath[0].sub.length"
                  bottom
                  color="warning"
                >
                  <template #activator="forceTip">
                    <v-btn
                      v-model="forceDelete"
                      :color="forceStatus"
                      class="mr-2"
                      small
                      v-bind="forceTip.attrs"
                      @click="forceDelete = !forceDelete"
                      v-on="forceTip.on"
                    >
                      <v-icon v-if="forceDelete">mdi-checkbox-marked-outline</v-icon>
                      <v-icon v-if="!forceDelete">mdi-checkbox-blank-outline</v-icon>
                      force
                    </v-btn>
                  </template>
                  <span>Erzwingt Löschung des gesamten Baumes!</span>
                </v-tooltip>
                <v-btn
                  v-if="!createNew"
                  :disabled="!forceDelete && !!selectedPath[0].sub.length"
                  color="error"
                  small
                  @click="deleteShortLink"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                  löschen
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.content-bg-info-text {
  background-color: #404040;
}
</style>